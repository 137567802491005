import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectAllOrders, fetchFirstOrders } from "./ordersSlice";
import { fetchProducts } from "./../products/productsSlice";

import SingleOrder from "./SingleOrder";

// Generic components
import { CircularProgress } from "./../../components/CircularProgress";
import { Content } from "./../../components/Content";

//Table components
import { Table, TableRow, TableCell, Paper } from "./../../components/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const OrdersList = () => {
	const dispatch = useAppDispatch();

	const orders = useAppSelector(selectAllOrders);
	const ordersStatus = useAppSelector((state) => state.orders.status);
	const productsStatus = useAppSelector((state) => state.products.status);
	const error = useAppSelector((state) => state.orders.error);

	useEffect(() => {
		if (ordersStatus === "idle") {
			dispatch(fetchFirstOrders());
		}
	}, [ordersStatus, dispatch]);

	useEffect(() => {
		if (productsStatus === "idle" || productsStatus === "incomplete") {
			dispatch(fetchProducts());
		}
	}, [productsStatus, dispatch]);

	if (
		ordersStatus === "loading" ||
		ordersStatus === "idle" ||
		productsStatus === "loading" ||
		productsStatus === "idle"
	) {
		return <CircularProgress />;
	} else if (ordersStatus === "failed") {
		return <Content>⛔️ {error}</Content>;
	} else if (ordersStatus === "succeeded") {
		if (orders.length > 0) {
			return (
				<TableContainer component={Paper} style={{ whiteSpace: "nowrap" }}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell style={{ width: "20px" }} />
								<TableCell>Id</TableCell>
								<TableCell>Nome</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orders.map((order, index) => {
								// @ts-expect-error
								return <SingleOrder key={order.id} id={order.id} />;
							})}
						</TableBody>
					</Table>
				</TableContainer>
			);
		} else {
			return <Content>⚠️ Nessun ordine trovato</Content>;
		}
	} else return null;
};

export default OrdersList;
