import { useEffect } from "react";
import {
	fetchProjects,
	selectAllProjects,
	setActiveProject,
} from "./projectsSlice";

import { Button, IconButton } from "./../../components/Button";

import { Content } from "./../../components/Content";
import { SecondTitle } from "./../../components/Heading";
import { CircularProgress } from "./../../components/CircularProgress";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

const ProjectsList = () => {
	const dispatch = useAppDispatch();
	const projects = useAppSelector(selectAllProjects);

	const projectsStatus = useAppSelector((state) => state.projects.status);
	const error = useAppSelector((state) => state.projects.error);

	const history = useHistory();

	useEffect(() => {
		if (projectsStatus === "idle") {
			dispatch(fetchProjects());
		}
	}, [projectsStatus, dispatch]);

	const handleButtonClick = (file_name: string) => {
		dispatch(setActiveProject(file_name));
	};

	if (projectsStatus === "loading" || projectsStatus === "idle") {
		return <CircularProgress />;
	} else if (projectsStatus === "failed") {
		return <span>{error}</span>;
	} else if (projectsStatus === "succeeded") {
		if (projects.length > 0) {
			return (
				<div
					style={{
						display: "grid",
						width: "100%",
						maxWidth: "1280px",
						gap: "1rem",
					}}
				>
					{projects.map((project, index) => (
						<div
							style={{
								padding: 20,
								backgroundColor: "var(--grey-light)",
								borderRadius: 5,
								boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
							}}
							key={index}
						>
							<SecondTitle>{project.name}</SecondTitle>
							<Content>{project.description}</Content>
							<br />
							<Button onClick={() => handleButtonClick(project.name)}>
								Apri
							</Button>
						</div>
					))}
				</div>
			);
		} else {
			return <Content>⚠️ Non ci sono progetti</Content>;
		}
	} else return null;
};

export default ProjectsList;
