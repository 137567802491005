import { combineReducers, configureStore } from "@reduxjs/toolkit";
import itemsReducer from "./features/items/itemsSlice";
import componentsReducer from "./features/components/componentsSlice";
import cartReducer from "./features/cart/cartSlice";
import ordersReducer from "./features/orders/ordersSlice";
import productsReducer from "./features/products/productsSlice";
import userReducer from "./features/user/userSlice";
import projectsReducer from "./features/projects/projectsSlice";

const rootReducer = combineReducers({
	items: itemsReducer,
	components: componentsReducer,
	cart: cartReducer,
	orders: ordersReducer,
	products: productsReducer,
	user: userReducer,
	projects: projectsReducer,
});

const store = configureStore({
	reducer: rootReducer,
	devTools: true,
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;