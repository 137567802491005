import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./css/index.css";
import "./css/variables.css";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
//import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga";

// Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

ReactGA.initialize("G-SZGT0N8TKN");
ReactGA.pageview(window.location.pathname + window.location.search);

// Production only section!
if (process.env.NODE_ENV !== "development") {
	// Disable console.log
	console.log = () => {};

	// Sentry
	Sentry.init({
		dsn: "https://4704b1c124b649d8b0d859a855d7edb2@o296289.ingest.sentry.io/6093542",
		integrations: [new BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);

//serviceWorker.unregister();
