import { useEffect, useRef, useState } from "react";

import { setActiveProject } from "./projectsSlice";

import { Button, IconButton } from "./../../components/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../../hooks";
import CreateDurox from "../../durox/durox";
import { Main } from "../../components/structure/Main";
import { Title } from "../../components/Heading";

import AuthService from "./../../components/auth.service";
import axios from "axios";
import settings from "./../../settings.json";
import { CircularProgress } from "../../components/CircularProgress";
import { FieldType, FieldReferenceInterface } from "./FieldType";
import { FieldTree } from "./ProjectFields";
import { TextInput } from "../../components/Form";
import { useHistory } from "react-router-dom";
import APIS from "../../apis";

// const OldSingleProject = () => {
// 	const dispatch = useAppDispatch();

// 	const projectName = useAppSelector((state) => state.projects.activeProject);

// 	const handleBackButtonClick = () => {
// 		dispatch(setActiveProject(""));
// 	};

// 	return (
// 		<div>
// 			<IconButton
// 				aria-label="close"
// 				onClick={handleBackButtonClick}
// 				style={{ position: "absolute", top: "1rem", left: "6rem" }}
// 			>
// 				<FontAwesomeIcon className="nav-icon" icon={faArrowLeft} />
// 			</IconButton>
// 			{projectName === "Durox" ? <CreateDurox /> : <PanicProject />}
// 		</div>
// 	);
// };

const SingleProject = () => {
	const dispatch = useAppDispatch();
	const [field, setField] = useState<FieldType | null>(null);
	const [status, setStatus] = useState<
		"idle" | "loading" | "failed" | "succeeded"
	>("idle");
	const [error, setError] = useState<String>("");
	const fieldsRef = useRef<FieldReferenceInterface>(null);
	const [name, setName] = useState("");

	const history = useHistory();

	const [submitState, setSubmitState] = useState<
		"idle" | "loading" | "failed" | "succeeded"
	>("idle");
	const [submitError, setSubmitError] = useState("");

	useEffect(() => {
		AuthService.authHeader().then(async (token) => {
			setStatus("loading");
			axios
				.get(settings.webservice_url + "projects/" + activeProject, {
					headers: token,
				})
				.then((response) => {
					console.log(response.data);
					setField(response.data);
					setStatus("succeeded");
				})
				.catch((error) => {
					setError(error);
					setStatus("failed");
				});
		});
	}, []);

	const handleSubmit = () => {
		if (fieldsRef.current) {
			const fieldsReady = fieldsRef.current.getReady();

			if (!fieldsReady) {
				setSubmitState("failed");
				setSubmitError("Non tutti i campi sono compilati");
				return;
			}

			if (name === "") {
				setSubmitState("failed");
				setSubmitError("Nome articolo non inserito");
				return;
			}

			setSubmitState("loading");

			const formData = fieldsRef.current.submitForm();

			AuthService.authHeader().then(async (token) => {
				axios
					.patch(
						settings.webservice_url + APIS.projects + activeProject + "/",
						formData,
						{
							headers: token,
						}
					)
					.then((response) => {
						if (!response.data.unit_price)
							throw Error("return message format error");

						const data = {
							name: name,
							project_name: activeProject,
							project_data: formData,
							unit_price: response.data.unit_price,
						};

						axios
							.post(settings.webservice_url + APIS.savedProjects, data, {
								headers: token,
							})
							.then((response) => {
								setSubmitState("succeeded");
								history.push("/items/projects");
							})
							.catch((error) => {
								setSubmitState("failed");
								setSubmitError(JSON.stringify(error.data));
							});
					})
					.catch((error) => {
						setSubmitState("failed");
						setSubmitError(JSON.stringify(error.data));
					});
			});
		}
	};

	const activeProject = useAppSelector((state) => state.projects.activeProject);
	if (status === "idle" || status === "loading" || !field)
		return (
			<Main>
				<CircularProgress />
			</Main>
		);
	else if (status === "failed")
		return (
			<Main>
				<span>{error}</span>
			</Main>
		);
	else
		return (
			<Main>
				<div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
					<IconButton
						aria-label="close"
						onClick={() => dispatch(setActiveProject(""))}
						style={{ height: "3rem", width: "3rem" }}
					>
						<FontAwesomeIcon className="nav-icon" icon={faArrowLeft} />
					</IconButton>
					<Title>{activeProject}</Title>
				</div>
				<div
					style={{ display: "flex", width: "100%", justifyContent: "center" }}
				>
					<div style={{ display: "flex", width: "100%", maxWidth: "1280px" }}>
						<div
							style={{
								display: "flex",
								width: "22rem",
								flexDirection: "column",
								gap: "1rem",
							}}
						>
							<FieldTree field={field} ref={fieldsRef} />
							<TextInput
								style={{ width: "100%" }}
								id="name"
								name="name"
								type="text"
								placeholder="Nome articolo"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<Button onClick={handleSubmit} style={{ height: "3rem" }}>
								{submitState === "loading" ? <CircularProgress /> : "Salva"}
							</Button>
							{submitState === "failed" ? <span>{submitError}</span> : null}
						</div>
					</div>
				</div>
			</Main>
		);
};

export default SingleProject;
