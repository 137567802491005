import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from "@reduxjs/toolkit";

// Api
import axios from "axios";
import AuthService from "./../../components/auth.service";
import settings from "./../../settings.json";
import { RootState } from "../../store";
import APIS from "../../apis";

// Group by function
const groupBy = (xs: any[], key: string) => {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

export const fetchItemImage = createAsyncThunk(
	"items/fetchItemImage",
	async (itemId, { getState, requestId }) => {
		// @ts-expect-error
		const item = getState().items.items.find((item) => item.id === itemId);

		console.log("Getting image for ", itemId);
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.get(item.svg, { headers: token })
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					throw error.response.data.detail;
				});
		});
	}
);

export const fetchItems = createAsyncThunk("items/fetchItems", async () => {
	const getToken = AuthService.authHeader();
	return getToken.then((token) => {
		return axios
			.get(settings.webservice_url + APIS.items, { headers: token })
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				throw error.response.data.detail;
			});
	});
});

export const removeItem = createAsyncThunk(
	"items/removeItem",
	// @ts-expect-error
	async ({ itemUrl }) => {
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.delete(itemUrl, { headers: token })
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log({ ...error });
				});
		});
	}
);

export const addItemToCart = createAsyncThunk(
	"items/addItemToCart",
	// @ts-expect-error
	async ({ itemUrl }) => {
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.post(
					settings.webservice_url + APIS.cart,
					{ item: itemUrl },
					{ headers: token }
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log({ ...error });
				});
		});
	}
);

export const removeItemFromCart = createAsyncThunk(
	"items/removeItemFromCart",
		// @ts-expect-error
	async ({ itemId, source }) => {
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.delete(
					settings.webservice_url + APIS.cart + source + "/" + itemId + "/",
					{ headers: token }
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log({ ...error });
				});
		});
	}
);

export const updateItemCartQuantity = createAsyncThunk(
	"items/updateItemCartQuantity",
		// @ts-expect-error
	async ({ itemId, source, quantity }) => {
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.patch(
					settings.webservice_url + APIS.cart + source + "/" + itemId + "/",
					{ quantity: quantity },
					{ headers: token }
				)
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log({ ...error });
				});
		});
	}
);

export const clearCart = createAsyncThunk("orders/clearCart", async () => {
	const getToken = AuthService.authHeader();
	return getToken.then((token) => {
		return axios
			.delete(settings.webservice_url + APIS.cart, { headers: token })
			.then((response) => {
				console.log(response.data);
				return response.data;
			})
			.catch((error) => {
				throw error.response.data.detail;
			});
	});
});

export const itemsSlice = createSlice({
	name: "items",
	initialState: {
		items: [],
		searchingItemName: "",
		itemsImages: {},
		status: "idle",
		error: null,
	},
	reducers: {
		setSearchingItemName(state, action) {
			state.searchingItemName = action.payload;
		},
	},
	extraReducers: {
		// @ts-expect-error
		[fetchItems.pending]: (state, action) => {
			state.status = "loading";
		},
		// @ts-expect-error
		[fetchItems.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.items = action.payload;
		},
		// @ts-expect-error
		[fetchItems.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
		// @ts-expect-error
		[fetchItemImage.fulfilled]: (state, action) => {
			state.itemsImages[action.meta.arg] = URL.createObjectURL(
				new Blob([action.payload], {
					type: "image/svg+xml",
				})
			);
		},
		// @ts-expect-error
		[clearCart.fulfilled]: (state, action) => {
			state.status = "idle";
		},
		// @ts-expect-error
		[clearCart.rejected]: (state, action) => {
			state.error = action.error.message;
		},
		// @ts-expect-error
		[removeItem.fulfilled]: (state, action) => {
			const urlToRemove = action.meta.arg.itemUrl;
			state.items = state.items.filter(function (obj: { url: any; }) {
				return obj.url !== urlToRemove;
			});
		},
		// @ts-expect-error
		[updateItemCartQuantity.fulfilled]: (state, action) => {
			const index = state.items.findIndex(
				(item: { id: any; }) => item.id === action.payload.id
			);
			let temp = state.items;
			temp[index].cart_quantity = action.payload.cart_quantity;
			temp[index].unit_price = action.payload.unit_price;
			state.items = temp;
		},
		// @ts-expect-error
		[addItemToCart.fulfilled]: (state, action) => {
			const index = state.items.findIndex(
				(item: { id: any; }) => item.id === action.payload.id
			);
			let temp = state.items;
			temp[index].cart_quantity = 1;
			temp[index].datetime_added_to_cart =
				action.payload.datetime_added_to_cart;
			temp[index].unit_price = action.payload.unit_price;
			state.items = temp;
		},
		// @ts-expect-error
		[removeItemFromCart.fulfilled]: (state, action) => {
			const index = state.items.findIndex(
				(item: { id: any; }) => item.id === action.meta.arg.itemId
			);
			let temp = state.items;
			temp[index].cart_quantity = 0;
			temp[index].datetime_added_to_cart = null;
			state.items = temp;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setSearchingItemName } = itemsSlice.actions;

export default itemsSlice.reducer;

export const selectCart = (state: RootState) => {
	if (state.items.items) {
		return state.items.items
			// @ts-expect-error
			.filter((item) => item.cart_quantity > 0)
			.sort(
				(b, a) =>
					// @ts-expect-error
					new Date(a.datetime_added_to_cart) -
					// @ts-expect-error
					new Date(b.datetime_added_to_cart)
			);
	} else return null;
};

export const selectCartItemQuantity = (state: RootState) => {
	if (state.items.items) {
		// @ts-expect-error
		return state.items.items.filter((item) => item.cart_quantity > 0).length;
	} else return null;
};

export const selectCartTotalPrice = (state: RootState) => {
	let total = 0;
	if (state.items.items) {
		// @ts-expect-error
		const items = state.items.items.filter((item) => item.cart_quantity > 0);
		items.forEach(function (item, index) {
			// @ts-expect-error
			total += item.unit_price * item.cart_quantity;
		});
		return total.toFixed(2);
	} else {
		return null;
	}
};

export const selectAllItems = (state: RootState) => state.items.items;

// Fast (<1 ms)
export const selectAllItemsGroupped = createSelector(
	[(state) => selectAllItems(state), (state) => state.items.searchingItemName],
	(items: any[], toSearch) => {
		if (items) {
			const sortedItems = items.slice().filter((item) => item.id != undefined).sort((a, b) => a.id < b.id ? 1 : -1)
			//console.log(sortedItems);
			const groupsObj = groupBy(sortedItems, "group");
			let grouppedItems = {};
			if (toSearch !== "") {
				for (const group of Object.keys(groupsObj)) {
					const searchedItems = groupsObj[group].filter((item: { name: any; id: any; }) =>
						(item.name + item.id).toLowerCase().includes(toSearch.toLowerCase())
					);
					// @ts-expect-error
					if (searchedItems.length > 0) grouppedItems[group] = searchedItems;
				}
				return grouppedItems;
			} else return groupBy(sortedItems, "group");
		} else return null;
	}
);

// Fast (<1 ms)
export const selectItemsByGroup = createSelector(
	[(state) => selectAllItemsGroupped(state), (_, groupName) => groupName],
	(items, groupName) => {
		if (items) {
			return items[groupName];
		} else return null;
	}
);

export const selectItemById = (itemId: any) => (state: RootState) => {
	// @ts-expect-error
	return state.items.items.find((item) => item.id === itemId);
};

export const selectItemImageById = createSelector(
	[(state) => state.items.itemsImages, (_, itemId) => itemId],
	(itemsImages, itemId) => {
		return itemsImages[itemId];
	}
);
