import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectOrderById } from "./ordersSlice";
import { isSearchingProducts } from "../products/productsSlice";

import ProductsList from "./../products/ProductsList";

//Table components
import { TableRow, TableCell } from "./../../components/Table";

// Generic components
import { SecondTitle } from "./../../components/Heading";
import { Content } from "./../../components/Content";
import { IconButton } from "./../../components/Button";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { CircularProgress } from "./../../components/CircularProgress";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const SingleOrder = (props: { id: number }) => {
	const { id } = props;
	const dispatch = useAppDispatch();
	const order = useAppSelector(selectOrderById(id));

	const isSearching = useAppSelector(isSearchingProducts);

	const [open, setOpen] = React.useState(false);

	const ordersStatus = useAppSelector((state) => state.orders.status);
	const error = useAppSelector((state) => state.orders.error);

	useEffect(() => {
		setOpen(isSearching);
	}, [isSearching]);

	useEffect(() => {
		if (ordersStatus === "idle") {
			//dispatch(fetchFirstOrders())
		}
	}, [ordersStatus, dispatch]);

	if (ordersStatus === "idle" || ordersStatus === "loading") {
		return <CircularProgress />;
	} else if (ordersStatus === "failed") {
		return <span>{error}</span>;
	} else if (ordersStatus === "succeeded") {
		if (order)
			return (
				<React.Fragment>
					<TableRow>
						<TableCell style={{ width: "20px" }}>
							<IconButton
								aria-label="expand row"
								size="small"
								onClick={() => setOpen(!open)}
							>
								{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						</TableCell>
						<TableCell>{order.id}</TableCell>
						<TableCell>
							<Content>
								{order.titolo === "" ? "Non ancora disponibile" : order.titolo}
							</Content>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
							<Collapse in={open} timeout="auto" unmountOnExit>
								<Box style={{ padding: "10px" }}>
									<SecondTitle>Prodotti</SecondTitle>
									<ProductsList orderId={order.id} />
								</Box>
							</Collapse>
						</TableCell>
					</TableRow>
				</React.Fragment>
			);
	} else return null;
};

export default SingleOrder;
