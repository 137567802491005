import { Fragment, useEffect, useState } from "react";

import PanicItemType from "./panicItemType";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@material-ui/core";
import { useAppSelector } from "../../../hooks";
import { selectCartOpen } from "../../cart/cartSlice";
import PanicItem from "./PanicItem";
import PanicGroup from "./PanicGroup";

const groupBy = (xs: any[], key: string | number) => {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

const PanicItemsList = (props: {
	items: PanicItemType[];
	setItems: (items: PanicItemType[]) => void;
}) => {
	const { items, setItems } = props;
	const cartOpen = useAppSelector(selectCartOpen);

	const grouppedItems: { [groupName: string]: PanicItemType[] } = groupBy(
		items,
		"group"
	);

	console.log(grouppedItems);

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
			{items.length === 0 ? (
				<span>Non ci sono articoli</span>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="collapsible table">
						<TableBody>
							{Object.keys(grouppedItems).map((groupName, index) =>
								groupName !== "" ? (
									<PanicGroup
										key={index}
										items={grouppedItems[groupName]}
										setItems={(newItems: PanicItemType[]) => {
											let itemsCopy = [...items];
											for (const newItem of newItems) {
												const itemIndex = itemsCopy.findIndex(
													(item) => item.id === newItem.id
												);
												itemsCopy[itemIndex] = {
													...newItem,
													group: itemsCopy[itemIndex].group,
												};
											}
											setItems(itemsCopy);
										}}
										groupName={groupName}
									/>
								) : null
							)}
							{grouppedItems[""] ? (
								<>
									<TableRow>
										<TableCell>Codice</TableCell>
										<TableCell>Nome</TableCell>
										<TableCell>Immagine</TableCell>
										<TableCell>{cartOpen ? "Ordina" : null}</TableCell>
										<TableCell style={{ width: "15rem" }} />
									</TableRow>
									{grouppedItems[""].map((item, index) => (
										<PanicItem
											item={item}
											setItem={(newItem: PanicItemType) => {
												let itemsCopy = [...items];
												const itemIndex = itemsCopy.findIndex(
													(item) => item.id === newItem.id
												);
												itemsCopy[itemIndex] = newItem;
												setItems(itemsCopy);
											}}
											key={index}
										/>
									))}
								</>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default PanicItemsList;
