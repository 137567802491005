import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

// Api
import axios from "axios";
import {
	isSearchingProducts,
	selectAllProducts,
	selectSearchedProducts,
} from "../products/productsSlice";
import AuthService from "./../../components/auth.service";
import settings from "./../../settings.json";
import { RootState } from "../../store";
import APIS from "../../apis";
import { OrderType } from "./OrderType";
import { useAppSelector } from "../../hooks";

export const fetchFirstOrders = createAsyncThunk(
	"orders/fetchFirstOrders",
	async () => {
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.get(settings.webservice_url + APIS.orders, { headers: token })
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					throw error.response.data.detail;
				});
		});
	}
);

export const fetchCreatedOrderById = createAsyncThunk(
	"orders/fetchCreatedOrderById",
	async (orderId, { getState, requestId }) => {
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.get(settings.webservice_url + APIS.orders + orderId, { headers: token })
				.then((response) => {
					// @ts-expect-error
					const orderPosition = getState()
					// @ts-expect-error

						.orders.orders.map((order) => order.id)
						.indexOf(response.data.id);
					if (orderPosition === -1) return response.data;
					else return null;
				})
				.catch((error) => {
					throw error.response.data.detail;
				});
		});
	}
);

interface CreateOrderMetaState {
	orders: {
		orders: []
		newOrderInfo: {
			deliveryDate: string;
			description: string;
		}
	}
}

export const createOrder = createAsyncThunk(
	"orders/createOrder",
	async (dispatch, store ) => {
		const state = store.getState() as CreateOrderMetaState
		const getToken = AuthService.authHeader();

		return getToken.then((token) => {

			// Generate data to send
			const data = {
				prodotti: state
					// @ts-expect-error
					.items.items.filter((item) => item.cart_quantity > 0)
					// @ts-expect-error
					.map((item) => item.id),
				titolo:
					state.orders.newOrderInfo.description ?
					state.orders.newOrderInfo.description :
					"Particolari di carpenteria",
				data_consegna: state.orders.newOrderInfo.deliveryDate,

			};

			// Send data
			return axios
				.post(settings.webservice_url + APIS.orders, data, { headers: token })
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log(error);
					throw error.response.data.detail;
				});
		});
	}
);

type OrdersState = {
	orders: OrderType[],
	searchingProductName: string,
	filterOnShippingReady: boolean,
	status: 'idle' | 'pending' | 'succeeded' | 'failed' | 'loading';
	newOrderStatus: string;
	newOrderError: string | null;
	newOrderInfo: {
		deliveryDate: string;
		description: string;
	}
	error: string | null;
}

const initialState: OrdersState = {
	orders: [],
	searchingProductName: "",
	filterOnShippingReady: false,
	status: "idle",
	newOrderStatus: "idle",
	newOrderError: null,
	newOrderInfo: {
		deliveryDate: new Date().toJSON().slice(0, 10),
		description: "",
	},
	error: null,
}

export const ordersSlice = createSlice({
	name: "orders",
	initialState,
	reducers: {
		setSearchingProductName(state, action) {
			state.searchingProductName = action.payload;
		},
		setFilterOnShippingReady(state, action) {
			state.filterOnShippingReady = action.payload;
		},
		setNewOrderInfoDescription(state, action: PayloadAction<string>)  {
			state.newOrderInfo.description = action.payload;
		},
		setNewOrderInfoDeliveryDate(state, action: PayloadAction<string>)  {
			state.newOrderInfo.deliveryDate = action.payload;
		},
	},
	extraReducers: {
		// @ts-expect-error

		[fetchFirstOrders.pending]: (state, action) => {
			if (state.status === "idle") state.status = "loading";
		},
		// @ts-expect-error

		[fetchFirstOrders.fulfilled]: (state, action) => {
			// Add any fetched posts to the array
			state.orders = action.payload.results;
			state.status = "succeeded";
		},
		// @ts-expect-error

		[fetchFirstOrders.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
		// @ts-expect-error

		[fetchCreatedOrderById.pending]: (state, action) => {},
		// @ts-expect-error
		[fetchCreatedOrderById.fulfilled]: (state, action) => {
			if (action.payload) state.orders.push(action.payload);
			state.newOrderInfo.deliveryDate = initialState.newOrderInfo.deliveryDate;
			state.newOrderInfo.description = initialState.newOrderInfo.description;
		},
		// @ts-expect-error

		[fetchCreatedOrderById.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
		// @ts-expect-error

		[createOrder.fulfilled]: (state, action) => {
			localStorage.removeItem("newOrderName");
			localStorage.removeItem("newOrderDeliveryDate");
			state.newOrderStatus = "succeeded";
		},
		// @ts-expect-error

		[createOrder.pending]: (state, action) => {
			state.newOrderStatus = "loading";
		},
		// @ts-expect-error

		[createOrder.rejected]: (state, action) => {
			state.newOrderStatus = "failed";
			state.newOrderError = action.error.message;
		},
	},
});

export const {
	setSearchingProductName,
	setFilterOnShippingReady,
	setNewOrderInfoDescription,
	setNewOrderInfoDeliveryDate
} = ordersSlice.actions;

export default ordersSlice.reducer;

export const selectAllOrders = (state: RootState) => {
	let orders = state.orders.orders.slice();
	
	const isSearching = isSearchingProducts(state);
	if (isSearching){
		const searchedProductsOrders = selectSearchedProducts(state).map((product)=>product.commesse)
		
		orders = orders.filter((order)=> searchedProductsOrders.includes(order.id))
	}

	/*if (isSearching) {
		orders = orders.filter((order) =>
			order.prodotti.find((orderProduct) =>
				searchedProductsIds.includes(orderProduct)
			)
		);
	}*/

	// Sort orders by id
	return orders.sort((b, a) => a.id - b.id);
};

export const selectOrderById = (orderId: number) => (state: RootState) => {
	return state.orders.orders.find((order) => order.id === orderId);
};

export const selectNewOrderInfo = (state: RootState) => state.orders.newOrderInfo;

export const selectFilterOnShippingReady = (state: RootState) =>
	state.orders.filterOnShippingReady;
