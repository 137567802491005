import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../hooks";

import { selectProductById } from "./productsSlice";

import { Chip } from "./../../components/Chip";

//Table components
import { TableRow, TableCell } from "./../../components/Table";
import { CircularProgress } from "./../../components/CircularProgress";

const getColor = (state_text: string) => {
	if (state_text.includes("ordine confermato")) return "#5c3485";
	else if (state_text.includes(" per la lavorazione")) return "#85345d";
	else if (state_text.includes("in lavorazione")) return "#855d34";
	else if (state_text.includes("parzialmente completato")) return "#5d8534";
	else if (state_text.includes(" per la consegna")) return "#34855d";
	else if (state_text.includes("consegnat")) return "#345d85";
	else return "#0e0e0e";
};

const ProductState = (props: { productId: number }) => {
	const { productId } = props;
	const product = useAppSelector(selectProductById(productId));

	if (product)
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: 10,
					alignItems: "left",
				}}
			>
				{product.state_text.split(",").map((state: string, index) => (
					<Chip key={index} color={getColor(state)}>
						{state}
					</Chip>
				))}
			</div>
		);
	else return null;
};

const SingleProduct = (props: { productId: number }) => {
	const { productId } = props;
	const dispatch = useAppDispatch();
	const product = useAppSelector(selectProductById(productId));

	const productsStatus = useAppSelector((state) => state.products.status);
	const error = useAppSelector((state) => state.products.error);

	useEffect(() => {
		if (productsStatus === "idle" || productsStatus === "incomplete") {
			//dispatch(fetchProducts())
		}
	}, [productsStatus, dispatch]);

	if (productsStatus === "loading" || productsStatus === "idle") {
		return <CircularProgress />;
	} else if (productsStatus === "failed") {
		return <span>{error}</span>;
	} else if (
		productsStatus === "succeeded" ||
		productsStatus === "incomplete" ||
		productsStatus === "loading_next"
	) {
		if (product) {
			return (
				<TableRow selected={product.searched}>
					<TableCell component="th" scope="product">
						{product.posizione}
					</TableCell>
					<TableCell
						style={{
							whiteSpace: "normal",
							wordWrap: "break-word",
						}}
					>
						{product.descrizione}
					</TableCell>
					<TableCell>{product.quantita}</TableCell>
					<TableCell>
						{product.prezzo_unitario
							? product.prezzo_unitario.toFixed(2) + " €"
							: ""}
					</TableCell>
					<TableCell>{product.data_consegna}</TableCell>
					<TableCell>
						<ProductState productId={productId} />
					</TableCell>
				</TableRow>
			);
		} else {
			return null;
		}
	} else return null;
};

export default SingleProduct;
