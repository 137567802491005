import styled from "styled-components";

interface CardProps {
	readonly padding?: string;
	readonly display?: string;
	readonly rows?: string;
	readonly align?: string;
	readonly margin?: string;
	readonly justify?: string;
	readonly columns?: string;
	readonly direction?: string;
}

export const Card = styled.fieldset<CardProps>`
	background-color: transparent;
	border-radius: 5px;
	border: 2px solid var(--grey);
	padding: ${(props) => (props.padding ? props.padding : "20px")};
	display: ${(props) => (props.display ? props.display : "flex")};
	grid-template-columns: ${(props) => (props.columns ? props.columns : "")};
	grid-template-rows: ${(props) => (props.rows ? props.rows : "")};
	align-items: ${(props) => (props.align ? props.align : "left")};
	margin: ${(props) => (props.margin ? props.margin : "10px")};
	justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
	flex-direction: ${(props) => (props.direction ? props.direction : "row")};
	box-sizing: border-box;
	color: white;
	width: 100%;
`;

export const CardTitle = styled.legend`
	background-color: transparent;
	padding: 0 5px 0px 5px;
	border-radius: 5px;
	color: white;
	width: auto;
	margin-bottom: -10px;
	font-size: 1rem;
`;
