import { useState } from "react";
import SavedProjectType from "./SavedProjectType";
import { Checkbox, TableCell, TableRow } from "../../../components/Table";
import { IconButton } from "../../../components/Button";
import { Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SavedProjectDetailDialog from "./SavedProjectDetaiDialog";
import { useAppSelector } from "../../../hooks";
import { selectCartOpen } from "../../cart/cartSlice";
import OutlinedNumberInput from "../../../components/OutlinedNumberInput";
import APIS from "../../../apis";
import settings from "./../../../settings.json";
import AuthService from "../../../components/auth.service";
import axios from "axios";
import SavedProjectDeleteDialog from "./SavedProjectDeleteDialog";

const SavedProject = (props: {
	item: SavedProjectType;
	setItem: (newItem: SavedProjectType | null) => void;
}) => {
	const { item, setItem } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [detailDialogOpen, setDetailDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const cartOpen = useAppSelector(selectCartOpen);

	let editTimer: NodeJS.Timeout | null;
	const handleNumberChange = (newValue: number) => {
		if (editTimer) clearTimeout(editTimer);
		editTimer = setTimeout(() => {
			if (newValue != item.cart_quantity) patchCartNumber(newValue);
		}, 500);
	};

	const handleToggleItemInCart = () => {
		if (item.cart_quantity > 0) patchCartNumber(0);
		else patchCartNumber(1);
	};

	const patchCartNumber = (newValue: number) => {
		const data = {
			cart_quantity: newValue,
		};
		AuthService.authHeader().then((token) => {
			axios
				.patch(
					settings.webservice_url + APIS.savedProjects + item.id + "/",
					data,
					{ headers: token }
				)
				.then((response) => {
					setItem(response.data);
				})
				.catch((error) => {
					console.error(error);
				});
		});
	};

	return (
		<TableRow>
			<TableCell>{item.name}</TableCell>
			<TableCell>{item.project_name}</TableCell>
			<TableCell>
				{cartOpen ? (
					<Checkbox
						checked={item.cart_quantity > 0 ? true : false}
						onChange={handleToggleItemInCart}
					/>
				) : null}
			</TableCell>
			<TableCell style={{ width: "15rem" }}>
				{item.cart_quantity > 0 && cartOpen ? (
					<OutlinedNumberInput
						initValue={item.cart_quantity}
						minValue={1}
						onChange={handleNumberChange}
					/>
				) : null}
			</TableCell>
			<TableCell>
				<>
					<IconButton
						onClick={(e) => {
							if (e) setAnchorEl(e.currentTarget);
						}}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						keepMounted
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={() => {
							setAnchorEl(null);
						}}
					>
						<MenuItem
							onClick={() => {
								setDetailDialogOpen(true);
								setAnchorEl(null);
							}}
						>
							Dettagli
						</MenuItem>
						<SavedProjectDetailDialog
							open={detailDialogOpen}
							setOpen={setDetailDialogOpen}
							item={item}
						/>
						<MenuItem
							onClick={() => {
								setDeleteDialogOpen(true);
								setAnchorEl(null);
							}}
						>
							Elimina
						</MenuItem>
						<SavedProjectDeleteDialog
							open={deleteDialogOpen}
							setOpen={setDeleteDialogOpen}
							item={item}
							setItem={setItem}
						/>
					</Menu>
				</>
			</TableCell>
		</TableRow>
	);
};

export default SavedProject;
