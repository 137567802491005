import React, { useState } from "react";
import styled from "styled-components";

// Components
import { Paper, IconButton, InputBase, Tooltip } from "@material-ui/core";

// Icons
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Button } from "./Button";

interface TextInputContainerProps {
	readonly block: boolean;
}

export const TextInputContainer = styled.div<TextInputContainerProps>`
	float: left;
	object-fit: contain;
	width: ${(props) => (props.block ? "100%" : "auto")};
`;

interface TextInputProps {
	readonly block?: boolean;
}

export const TextInput = styled.input<TextInputProps>`
	width: ${(props) => (props.block ? "100%" : "auto")};
	margin: 0px;
	padding: 5px;
	border-radius: 5px;
	border: none;
	background-color: var(--grey);
	color: white;
	outline: none;
	transition: 0.3s;
	&:hover {
		background-color: var(--grey-light);
	}
	&:active {
		background-color: var(--grey-light);
	}
`;

export const TextInputLabel = styled.label`
	color: white;
	font-size: 0.8rem;
	display: block;
	margin-bottom: 0.2rem;
`;

export const SelectInput = styled.select`
	min-width: 160px;
	margin: 5px 10px 5px 10px;
	padding: 5px;
	border-radius: 5px;
	border: none;
	background-color: var(--grey);
	color: white;
	outline: none;
	transition: 0.3s;
	cursor: pointer;
	&:hover {
		background-color: var(--grey-light);
	}
`;

export const SearchField = (props: {
	label: string;
	onSubmit: (input: string) => void;
}) => {
	const { label, onSubmit } = props;
	const [value, setValue] = useState("");

	// @ts-expect-error
	const onChangeValue = (e) => {
		setValue(e.target.value);
	};

	// @ts-expect-error
	const onEnter = (e) => {
		e.preventDefault();
		onSubmit(e.target.value);
	};

	const handleClear = () => {
		setValue("");
		onSubmit("");
	};

	const handleSubmit = () => {
		onSubmit(value);
	};

	return (
		<Paper
			component="form"
			style={{
				display: "grid",
				gridTemplateColumns: "3em auto 3em",
				alignItems: "center",
			}}
			// @ts-expect-error
			sx={{
				p: "5px",
			}}
		>
			<IconButton
				style={{ padding: 10, height: "2em", width: "2em" }}
				onClick={handleSubmit}
				aria-label="search"
			>
				<SearchIcon />
			</IconButton>
			<Tooltip
				title={"Premi ENTER o il tasto 'Cerca' per iniziare una ricerca"}
				placement="top"
			>
				<InputBase
					style={{ height: "3em" }}
					// @ts-expect-error
					sx={{ ml: 1, flex: 1 }}
					fullWidth={true}
					placeholder={label}
					inputProps={{
						"aria-label": label,
					}}
					value={value}
					onChange={onChangeValue}
					onKeyPress={(e) => {
						e.key === "Enter" && onEnter(e);
					}}
				/>
			</Tooltip>

			{value.length > 0 ? (
				<IconButton
					style={{ padding: 10, height: "2em", width: "2em" }}
					onClick={handleClear}
					aria-label="clear"
				>
					<ClearIcon />
				</IconButton>
			) : null}
		</Paper>
	);
};

export const NumericInput = (props: {
	value: number;
	setValue: (newValue: number) => void;
	unit?: string;
	maxValue?: number;
	minValue?: number;
}) => {
	const { value, setValue, unit, minValue, maxValue } = props;

	const handleChangeValue = (newValue: number) => {
		if (maxValue != undefined) {
			if (newValue > maxValue) {
				newValue = maxValue;
			}
		}

		if (minValue != undefined) {
			if (newValue < minValue) {
				newValue = minValue;
			}
		}

		setValue(newValue);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				minWidth: "10rem",
				justifyContent: "space-between",
				alignItems: "center",
				width: "100%",
				gap: "1rem",
				backgroundColor: "var(--grey-dark)",
				borderRadius: 5,
				height: "3rem",
			}}
		>
			<input
				value={value}
				onChange={(e) => handleChangeValue(parseInt(e.target.value))}
				type="number"
				style={{
					outlineWidth: 0,
					border: 0,
					backgroundColor: "var(--grey-dark)",
					width: "100%",
					color: "white",
					padding: 5,
					borderRadius: 5,
					paddingLeft: "1rem",
				}}
			/>
			{unit ? (
				<span
					style={{ color: "white", fontSize: "1rem", paddingRight: "1rem" }}
				>
					{unit}
				</span>
			) : null}
		</div>
	);
};
