import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";

// Api
import axios from "axios";
import AuthService from "./../../components/auth.service";
import settings from "./../../settings.json";
import APIS from "../../apis";
import { ProductType } from "./ProductType";
import { RootState } from "../../store";

export const fetchProducts = createAsyncThunk(
	"products/fetchProducts",
	async (dispatch, store) => {
		// @ts-expect-error
		const url = store.getState().products.next
			// @ts-expect-error
			? store.getState().products.next
			: settings.webservice_url + APIS.products;

		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.get(url, { headers: token })
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					throw error;
				});
		});
	}
);

export const fetchProductById = createAsyncThunk(
	"products/fetchProductById",
	async (id, { getState, requestId }) => {
		const getToken = AuthService.authHeader();

		const url = settings.webservice_url + APIS.products + id + "/"

		return getToken.then((token) => {
			return axios
				.get(url, { headers: token })
				.then((response) => {
					// @ts-expect-error
					let products: ProductType[] = getState().products.products.slice();
					const productPosition = products
						.map((product) => product.id)
						.indexOf(response.data.id);

					if (productPosition === -1) products.push(response.data);
					else products[productPosition] = response.data;

					return products;
				})
				.catch((error) => {
					throw error.response.data.detail;
				});
		});
	}
);

interface productsState {
	products: ProductType[];
	status: "idle" | "loading_next" | "loading" | "incomplete" | "failed" | "succeeded";
	error: string;
	next: string; // url
}

const initialState: productsState = {
	products: [],
	status: "idle",
	error: "",
	next: "",
}

export const productsSlice = createSlice({
	name: "products",
	initialState,
	reducers: {},
	extraReducers: {
		// @ts-expect-error
		[fetchProducts.pending]: (state, action) => {
			if (state.next) state.status = "loading_next";
			else if (state.status === "idle") state.status = "loading";
		},
		// @ts-expect-error
		[fetchProducts.fulfilled]: (state, action) => {
			// if it's first block then clear products
			if (!state.next) {
				state.products = [];
			}

			if (action.payload.results) {
				state.products = [...state.products, ...action.payload.results];
			}

			if (action.payload.next) {
				state.status = "incomplete";
			} else {
				state.status = "succeeded";
			}
			state.next = action.payload.next;
		},
		// @ts-expect-error
		[fetchProducts.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
		// @ts-expect-error
		[fetchProductById.pending]: (state, action) => {},
		// @ts-expect-error
		[fetchProductById.fulfilled]: (state, action) => {
			state.products = action.payload;
		},
		// @ts-expect-error
		[fetchProductById.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default productsSlice.reducer;

export const isSearchingProducts = (state: RootState) => {
	return (
		state.orders.searchingProductName.length > 0 ||
		state.orders.filterOnShippingReady
	);
};

export const selectAllProducts = (state: RootState) => state.products.products.slice().sort((a,b) => a.posizione - b.posizione)

export const selectSearchedProducts = (state: RootState) => {
	let products = selectAllProducts(state);
	const isSearching = isSearchingProducts(state);

	if (isSearching) {
		// filter for product name
		const productName = state.orders.searchingProductName;
		if (productName.length > 0) {
			products = products.filter((product) => {
				if (product.descrizione) {
					return product.descrizione.toLowerCase().includes(productName.toLowerCase())
				}
				return false;
			});
		}

		// filter for shipping ready
		const filterOnShippingReady = state.orders.filterOnShippingReady;
		if (filterOnShippingReady) {
			products = products.filter((product) =>
				product.state_text.includes("per la consegna")
			);
		}
	}

	return products;
};

export const selectProductById = (productId: number) => (state: RootState) => {
	let product = selectAllProducts(state).find(
		(product) => product.id === productId
	);

	if (!product) return null;

	let searchedProduct: ProductType & {searched: boolean} = { ...product, searched: false };

	// if searching find if this product is selected
	if (isSearchingProducts(state)) {
		const searchedProductsIds = selectSearchedProducts(state).map(
			(product) => product.id
		);
		if (searchedProductsIds.includes(searchedProduct.id))
			searchedProduct = { ...searchedProduct, searched: true };
	}

	return searchedProduct;
};

export const selectProductsByOrderId = (orderId: number) => (state: RootState) => {
	const products = selectSearchedProducts(state);

	//p = p.sort((a, b) => a.posizione < b.posizione);
	return products.filter((product) => product.commesse === orderId);
};
