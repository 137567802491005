import { default as OriginalCircularProgress } from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';

export const CircularProgress = withStyles((theme: Theme) =>
  createStyles({
    root:{
      color: 'white',
    }
  }),
)(OriginalCircularProgress);
