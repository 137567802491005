import { Box, Collapse, TableBody, TableContainer } from "@material-ui/core";
import { IconButton } from "../../../components/Button";
import { TableCell, TableRow } from "../../../components/Table";
import PanicItemType from "./panicItemType";
import { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PanicItemsList from "./PanicItemsList";

const PanicGroup = (props: {
	groupName: string;
	items: PanicItemType[];
	setItems: (items: PanicItemType[]) => void;
}) => {
	const [open, setOpen] = useState(false);
	const { groupName, items, setItems } = props;

	const genericItems: PanicItemType[] = items.map((item) => {
		return { ...item, group: "" };
	});

	return (
		<>
			<TableRow>
				<TableCell style={{ width: "20px" }}>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell colSpan={2}>{groupName}</TableCell>
				<TableCell colSpan={2}>{items.length} articoli</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ padding: 0 }} colSpan={5}>
					<Collapse
						in={open /*|| isSearchingItemName*/}
						timeout="auto"
						unmountOnExit
					>
						<Box style={{ padding: "10px" }}>
							<PanicItemsList items={genericItems} setItems={setItems} />
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default PanicGroup;
