//import { default as OriginalDrawer } from "@material-ui/core/Drawer";
//import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";

/*
export const Drawer = withStyles((theme) =>
	createStyles({
		root: (props) => ({
			width: props.width ? props.width : 240,
		}),
		paper: (props) => ({
			width: props.width ? props.width : 240,
			height: "100%",
			backgroundColor: "var(--grey-dark)",
			padding: 10,
			display: "flex",
			flexDirection: "column",
			flex: 1,
			gap: "10px",
		}),
	})
)(OriginalDrawer);
*/

export const DrawerFooter = styled.div`
	margin-top:auto;
`;

interface DrawerProps {
	readonly open: boolean;
}

export const Drawer = styled.div<DrawerProps>`
	position: fixed;
	width: 25rem;
	right: ${(props) => (props.open ? "0" : "-25rem")};
	top: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	padding: 1rem;

	background-color: var(--grey-dark);
	transition: 200ms;
	z-index: 100;

	@media screen and (max-width: 768px) {
		display: ${(props) => (!props.open ? "none" : "")};
		position: fixed;
		width: ${(props) => (props.open ? "100vw" : "0")};
		height: 100vh;
		overscroll-behavior: contain;
	}
`;
