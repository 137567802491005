import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectProductsByOrderId } from "./productsSlice";

import SingleProduct from "./../products/SingleProduct";

//Table components
import { Table, TableRow, TableCell, Paper } from "./../../components/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

import { Content } from "./../../components/Content";
import { CircularProgress } from "./../../components/CircularProgress";

const ProductsList = (props: { orderId: number }) => {
	const { orderId } = props;

	const products = useAppSelector(selectProductsByOrderId(orderId));

	const productsStatus = useAppSelector((state) => state.products.status);
	const error = useAppSelector((state) => state.products.error);

	if (productsStatus === "loading" || productsStatus === "idle") {
		return <CircularProgress />;
	} else if (productsStatus === "failed") {
		return <span>{error}</span>;
	} else if (
		productsStatus === "succeeded" ||
		productsStatus === "incomplete" ||
		productsStatus === "loading_next"
	) {
		if (products.length > 0) {
			return (
				<TableContainer component={Paper}>
					<Table aria-label="collapsible table">
						<TableHead>
							<TableRow>
								<TableCell>Posizione</TableCell>
								<TableCell>Descrizione</TableCell>
								<TableCell>Quantità</TableCell>
								<TableCell>Prezzo unitario</TableCell>
								<TableCell>Consegna richiesta</TableCell>
								<TableCell>Stato</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{products.map(
								(
									product: { id: number },
									index: React.Key | null | undefined
								) => (
									<SingleProduct key={index} productId={product.id} />
								)
							)}
						</TableBody>
					</Table>
				</TableContainer>
			);
		} else {
			return <Content>Non ci sono prodotti</Content>;
		}
	} else return null;
};

export default ProductsList;
