import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
	setSearchingProductName,
	selectFilterOnShippingReady,
	setFilterOnShippingReady,
} from "./../features/orders/ordersSlice";

// Components
import OrdersList from "./../features/orders/OrdersList";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { SearchField } from "./../components/Form";
import { Chip } from "../components/Chip";

import { Main } from "./../components/structure/Main";
import { Title } from "./../components/Heading";

const OrdersPage = () => {
	const dispatch = useDispatch();

	const filterOnShippingReady = useSelector(selectFilterOnShippingReady);

	const handleSearchChange = (value: string) => {
		dispatch(setSearchingProductName(value));
	};

	const handleShippingReadyChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		dispatch(setFilterOnShippingReady(e.target.checked));
	};

	return (
		<Main>
			<Title>Ordini</Title>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						maxWidth: "1280px",
						gap: "1rem",
					}}
				>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "3fr 1fr",
							gap: "1rem",
							gridTemplateRows: "3em",
							alignItems: "center",
						}}
					>
						<SearchField
							onSubmit={handleSearchChange}
							label={"Cerca un articolo"}
						/>

						<FormControlLabel
							control={
								<Checkbox
									checked={filterOnShippingReady}
									onChange={handleShippingReadyChange}
									name="checkbox"
									color="primary"
								/>
							}
							label={<Chip color={"#34855d"}>pronti per la consegna</Chip>}
						/>
					</div>

					<OrdersList />
				</div>
			</div>
		</Main>
	);
};

export default OrdersPage;
