import { useState } from "react";

import { Button } from "./../components/Button";
import { CenterBox } from "./../components/CenterBox";
import { Title } from "./../components/Heading";
import { CircularProgress } from "../components/CircularProgress";

//Toats
import { toast } from "react-toastify";

import ErrorIcon from "@material-ui/icons/Error";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";

import AuthService from "./../components/auth.service";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const LoginPage = () => {
	const history = useHistory();
	const [status, setStatus] = useState<"idle" | "pending" | "successful">(
		"idle"
	);
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [showError, setShowError] = useState<boolean>(false);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setStatus("pending");

		AuthService.login(email, password)
			.then(() => {
				setStatus("successful");
				history.push("/");
				//window.location.reload();
			})
			.catch((e) => {
				setStatus("idle");
				switch (e.message) {
					case "CredentialsError":
						setShowError(true);
						break;
					case "ConnectionError":
						toast.error(
							<div>
								<SignalWifiOffIcon /> Impossibile contattare il server
								<br />
								<b>Riprova più tardi</b>
							</div>,
							{
								hideProgressBar: true,
							}
						);
						break;
					case "GenericError":
						toast.error(
							<div>
								<SignalWifiOffIcon /> Impossibile contattare il server
								<br />
								<b>Riprova più tardi</b>
							</div>,
							{
								hideProgressBar: true,
							}
						);
						break;
					default:
						console.error(e);
				}
			});

		// reset
		setEmail("");
		setPassword("");
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					height: "calc(100% - 5rem)",
				}}
			>
				<CenterBox style={{ color: "black" }}>
					<form onSubmit={handleSubmit}>
						<div style={{ width: "100%", textAlign: "center" }}>
							<b style={{ color: "black" }}>mak</b>
							<span style={{ color: "var(--primary-color)" }}>
								<b>Easy</b>
							</span>
						</div>
						<Title align="center" color="black">
							Accedi
						</Title>

						{showError ? (
							<label className="error-message">
								<ErrorIcon /> Email o password errati
							</label>
						) : null}

						<div className="form-group">
							<label>Indirizzo email</label>
							<input
								name="email"
								type="email"
								className="form-control"
								placeholder="Inserisci email"
								value={email}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setEmail(e.target.value);
								}}
							/>
						</div>

						<div className="form-group">
							<label>Password</label>
							<input
								name="password"
								type="password"
								className="form-control"
								placeholder="Inserisci password"
								value={password}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setPassword(e.target.value);
								}}
							/>
						</div>

						<Button
							style={{ margin: 0 }}
							display="block"
							type="submit"
							value="Submit"
						>
							{status === "pending" ? (
								<CircularProgress
									size={20}
									style={{ verticalAlign: "middle" }}
								/>
							) : (
								"Accedi"
							)}
						</Button>
					</form>
				</CenterBox>
			</div>
		</>
	);
};

export default LoginPage;
