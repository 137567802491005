import React, { useEffect, useState } from "react";

// Redux
import store from "../store";
import { fetchCreatedOrderById, selectAllOrders } from "./orders/ordersSlice";
import { fetchProductById, selectAllProducts } from "./products/productsSlice";
import { useDispatch } from "react-redux";

// Websocket
import settings from "../settings.json";

// Auth Service
import AuthService from "../components/auth.service";

const actions = {
	orders: {
		create: fetchCreatedOrderById,
		id: "id",
	},
	products: {
		create: fetchProductById,
		update: fetchProductById,
		id: "id",
	},
	productionStateText: {
		create: fetchProductById,
		update: fetchProductById,
		id: "product_id",
	},
};

const NotificationInterface = () => {
	const [status, setStatus] = useState("idle");
	const dispatch = useDispatch();

	const startWsConnection = (customerId) => {
		const ws = new WebSocket(
			settings.websocket_url + "notification/?customer_id=" + customerId
		);

		ws.addEventListener("open", function () {
			console.log("WS: connection established");
		});

		ws.addEventListener("message", function (event) {
			const data = JSON.parse(event.data);

			// Check if item already exist before creating
			/*
			const item = data.item;
			const itemId = data.id;
			const action = data.action;
			if (action === "create") {
				const selectedItemLookupPath = actions[item].lookupPath;

				const storedItems =
					store.getState()[selectedItemLookupPath.slice][
						selectedItemLookupPath.sliceItem
					];

				const storedItemPosition = storedItems
					.map((item) => item.id)
					.indexOf(itemId);

				console.log(storedItemPosition);
			}*/

			const item = data.item;
			const action = data.action;

			if (actions[item]) {
				const selectedItem = actions[item];
				if (selectedItem[action]) {
					const selectedItemAction = selectedItem[action];
					const itemId = data[selectedItem.id];
					dispatch(selectedItemAction(itemId));
				}
			}
		});

		ws.onerror = (error) => {
			console.log("WS> ", error.message);
		};
	};

	useEffect(() => {
		if (status === "idle") {
			if (AuthService.getCustomerId()) {
				startWsConnection(AuthService.getCustomerId());
				setStatus("connected");
			}
		}
	}, []);

	return null;
};

export default NotificationInterface;
