import React, { useEffect, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

// React stuff
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Pages
import ProjectsPage from "./pages/ProjectsPage";
import ItemsPage from "./pages/ItemsPage";
import OrdersPage from "./pages/OrdersPage";
import LoginPage from "./pages/LoginPage";

import PrivateRoute from "./components/privateroute";
import {
	NavbarContainer,
	NavbarList,
	NavbarItem,
	NavbarLink,
	NavbarText,
	NavbarTitle,
	NavbarAccount,
	NavbarAccountTitle,
	NavbarExit,
} from "./components/structure/Navbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCubes,
	faCartArrowDown,
	faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

// Websocket interface
import NotificationInterface from "./features/NotificationInterface";

import AuthService from "./components/auth.service";
import Footer from "./Footer";

const NavbarComponent = () => {
	const [hideNavbar, setHideNavbar] = useState(false);
	const companyName = AuthService.getCompanyName();
	const permissions = AuthService.getPermission();

	useEffect(() => {
		// hide navbar on mobile on scrolling
		let prevScrollpos = window.scrollY;
		window.onscroll = () => {
			const currentScrollPos = window.scrollY;
			if (prevScrollpos > currentScrollPos) {
				setHideNavbar(false);
			} else {
				setHideNavbar(true);
			}
			prevScrollpos = currentScrollPos;
		};
	}, []);

	const handleLogOutClick = () => {
		AuthService.logout();
	};

	const itemsPageAvailable =
		permissions?.includes("can_access_panic_items") ||
		permissions?.includes("can_access_projects") ||
		permissions?.includes("can_access_durox");

	return (
		<NavbarContainer style={{ zIndex: 100 }} hide={hideNavbar}>
			<NavbarTitle>
				<b>mak</b>
				<span style={{ color: "var(--primary-color)" }}>
					<b>Easy</b>
				</span>
			</NavbarTitle>
			<NavbarAccount>
				<NavbarAccountTitle>
					{companyName ? companyName.charAt(0) : null}
				</NavbarAccountTitle>
				<NavbarText>{companyName}</NavbarText>
			</NavbarAccount>
			<NavbarList hide={hideNavbar}>
				<NavbarItem>
					<NavbarLink href="/orders">
						<FontAwesomeIcon className="nav-icon" icon={faCartArrowDown} />
						<NavbarText>Ordini</NavbarText>
					</NavbarLink>
				</NavbarItem>
				{itemsPageAvailable ? (
					<NavbarItem>
						<NavbarLink href="/items">
							<FontAwesomeIcon className="nav-icon" icon={faCubes} />
							<NavbarText>Articoli</NavbarText>
						</NavbarLink>
					</NavbarItem>
				) : null}
			</NavbarList>
			<NavbarExit href="/sign-in" onClick={handleLogOutClick}>
				<FontAwesomeIcon className="nav-icon" icon={faSignOutAlt} />
				<NavbarText className="nav-link">Logout</NavbarText>
			</NavbarExit>
		</NavbarContainer>
	);
};

const DefaultContainer = () => {
	return (
		<div style={{ minHeight: "calc(100vh - 5rem)" }}>
			<NavbarComponent />
			<Switch>
				<PrivateRoute path="/" component={OrdersPage} exact />
				<PrivateRoute path="/projects" component={ProjectsPage} exact />
				<PrivateRoute path="/items*" component={ItemsPage} exact />
				<PrivateRoute path="/orders" component={OrdersPage} exact />
			</Switch>
		</div>
	);
};

const theme = createTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#dd6007",
		},
		secondary: {
			main: "#424242",
		},
	},
	typography: {
		fontFamily: ["Fira Sans", "sans-serif"].join(","),
	},
});

class App extends React.Component {
	render() {
		return (
			<ThemeProvider theme={theme}>
				<ToastContainer />
				<NotificationInterface />
				<div className="App">
					<Router>
						<Switch>
							<Route path="/sign-in" component={LoginPage} />
							<Route component={DefaultContainer} />
						</Switch>
					</Router>
					<Footer />
				</div>
			</ThemeProvider>
		);
	}
}

export default App;
