import styled from 'styled-components'
import { default as OriginalFab } from '@material-ui/core/Fab';
import { default as OriginalIconButton } from '@material-ui/core/IconButton';
import { makeStyles, useTheme, Theme, createStyles, withStyles } from '@material-ui/core/styles';

interface ButtonProps {
  readonly display?: string;
}

export const Button = styled.button<ButtonProps>`
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  border: none;
  display: ${props => props.display ? props.display : "inline"};
  width: ${props => props.display === "block" ? "100%" : "auto"};
  padding: 5px 10px 5px 10px;
  margin: 5px;
  color: white;
  border-radius: 5px;
  transition: 0.3s;
  outline: none;
  &:hover{
    background-color: var(--primary-color-lighten);
    border-color: var(--primary-color-lighten);
    outline: none;
  }
  &:active{
    background-color: var(--primary-color-lighten);
    border-color: var(--primary-color-lighten);
    outline: none;
  }
  &:focus{
    background-color: var(--primary-color-lighten);
    border-color: var(--primary-color-lighten);
    outline: none;
  }
`;

interface SendButtonProps {
  readonly width?: string;
}

export const SendButton = withStyles((theme) =>
  createStyles({
    root: (props: SendButtonProps) => ({
      background: 'var(--primary-color);',
      color: 'white',
      fontFamily: "'Fira Sans', sans-serif",
      display: 'flex',
      width: props.width ? props.width : '100%',
      textTransform: 'none',
      boxShadow: 'none',
      outline: 'none !important',

      '&:hover': {
        background: 'var(--primary-color-lighten);',
      },
    }),
  }),
)(OriginalFab);

export const IconButton = withStyles((theme) =>
  createStyles({
    root: {
      color: 'white',
      padding: '5px',
    },
  }),
)(OriginalIconButton);
