import styled from "styled-components";

interface MainProps {
  readonly align?: "center";
}

export const Main = styled.div<MainProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;
	margin-left: 5rem;
	gap: 1rem;

	@media screen and (max-width: 768px) {
		margin: 0;
		margin-top: 5rem;
	}
`;

//margin-left: ${(props) => (props.align ? "0" : "5rem")};