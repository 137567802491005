import { useEffect, useState } from "react";
import { SearchField } from "../../../components/Form";
import PanicItemsList from "./PanicItemsList";
import PanicItemType from "./panicItemType";

const PanicItemsPage = (props: {
	items: PanicItemType[];
	setItems: (item: PanicItemType[]) => void;
}) => {
	const { items, setItems } = props;
	const [searchingItemName, setSearchingItemName] = useState("");
	const [filteredItems, setFilteredItems] = useState(items);

	useEffect(() => {
		if (searchingItemName !== "") {
			setFilteredItems(
				items.filter((item) =>
					item.name
						.toLocaleLowerCase()
						.includes(searchingItemName.toLocaleLowerCase())
				)
			);
		} else {
			setFilteredItems(items);
		}
	}, [searchingItemName, items]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				width: "100%",
				maxWidth: "1280px",
			}}
		>
			<SearchField
				label={"Cerca un articolo predefinito"}
				onSubmit={(newValue) => {
					setSearchingItemName(newValue);
				}}
			/>
			<PanicItemsList items={filteredItems} setItems={setItems} />
		</div>
	);
};

export default PanicItemsPage;
