const APIS = {
  panicItems: "panic_items/",
  token: "token/",
  tokenRefresh: "token/refresh/",
  user: "user/",
  preview: "upload/",
  components: "component/",
  cart: "cart/",
  sheets: "sheets/",
  products: "products/",
  projects: "projects/",
  orders: "orders/",
  items: "durox_items/",
  savedProjects: "saved_projects/",
  makeOrder: "make_order/"
}

export default APIS;