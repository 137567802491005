import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import SavedProjectType from "./SavedProjectType";
import { Button } from "../../../components/Button";

const SavedProjectDetailDialog = (props: {
	open: boolean;
	setOpen: (open: boolean) => void;
	item: SavedProjectType;
}) => {
	const { open, setOpen, item } = props;

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false);
			}}
		>
			<DialogTitle>Dettagli</DialogTitle>
			<DialogContent style={{ minWidth: "20rem", maxWidth: "30rem" }}>
				<h6>Dati</h6>
				<span style={{ whiteSpace: "break-spaces" }}>
					{JSON.stringify(item.project_data, null, "\t")}
				</span>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setOpen(false);
					}}
				>
					Chiudi
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SavedProjectDetailDialog;
