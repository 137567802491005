import {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { Dialog } from "../../../components/Dialog";
import SavedProjectType from "./SavedProjectType";
import { Button } from "../../../components/Button";
import APIS from "../../../apis";
import settings from "./../../../settings.json";
import AuthService from "../../../components/auth.service";
import axios from "axios";

const SavedProjectDeleteDialog = (props: {
	item: SavedProjectType;
	setItem: (newItem: SavedProjectType | null) => void;
	open: boolean;
	setOpen: (newOpen: boolean) => void;
}) => {
	const { item, setItem, open, setOpen } = props;

	const handleDelete = () => {
		AuthService.authHeader().then((token) => {
			axios
				.delete(settings.webservice_url + APIS.savedProjects + item.id + "/", {
					headers: token,
				})
				.then((response) => {
					setItem(null);
				})
				.catch((error) => {
					console.error(error);
				});
		});
		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>Elimina</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Sei sicuro di voler eliminare il progetto {item.name}?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Annulla</Button>
				<Button onClick={handleDelete}>Conferma</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SavedProjectDeleteDialog;
