import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";

type CartState = {
	open: boolean;
}

const initialState: CartState = {
	open: false
}

export const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		cartOpen(state) {
			console.log("reducer");
			state.open = true;
		},
		cartClose(state) {
			state.open = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const { cartOpen, cartClose } = cartSlice.actions;

export const selectCartOpen = (state: RootState) => state.cart.open;

export default cartSlice.reducer;
