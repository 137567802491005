import { Table, TableRow, TableCell, Paper } from "../../../components/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

// Generic components
import { Content } from "../../../components/Content";
import CartItemType from "./CartItemType";

const CartList = (props: { items: CartItemType[] }) => {
	const { items } = props;

	if (items.length === 0)
		return <Content>Seleziona un articolo da ordinare</Content>;
	else
		return (
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell size="small">Q.tà</TableCell>
							<TableCell size="small">Articolo</TableCell>
							<TableCell size="small">Prezzo unitario</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((item, index) => (
							<TableRow key={index}>
								<TableCell>
									<Content>{item.quantity}</Content>
								</TableCell>
								<TableCell>
									<Content>{item.name}</Content>
								</TableCell>
								<TableCell style={{ width: "70px" }}>
									<Content>{item.unitPrice} €</Content>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
};

export default CartList;
