import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    companyName: '',
    status: 'idle',
    error: null
  },
  reducers: {
    setCompanyName(state, action) {
      state.status = 'succeeded'
      console.log(action.payload)
      state.companyName = action.payload
    },
  }
})


export default userSlice.reducer

export const { setCompanyName } = userSlice.actions

export const selectCompanyName = (state: RootState) => state.user.companyName
