import { Main } from "./../components/structure/Main";
import { Title } from "./../components/Heading";

import ProjectsList from "./../features/projects/ProjectsList";
import SingleProject from "./../features/projects/SingleProject";
import { useAppSelector } from "../hooks";
import { IconButton } from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ProjectsPage = () => {
	const activeProject = useAppSelector((state) => state.projects.activeProject);
	const history = useHistory();

	if (activeProject === "") {
		return (
			<Main>
				<div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
					<IconButton
						aria-label="close"
						onClick={() => history.push("/items/projects")}
						style={{ height: "3rem", width: "3rem" }}
					>
						<FontAwesomeIcon className="nav-icon" icon={faArrowLeft} />
					</IconButton>
					<Title>Progetti</Title>
				</div>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<ProjectsList />
				</div>
			</Main>
		);
	} else {
		return <SingleProject />;
	}
};

export default ProjectsPage;
