import React from 'react';
import AuthService from "./auth.service";
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isUserAuthenticated = AuthService.getCurrentUser() !== null;
  return(
    <Route {...rest} render={(props) => (
        isUserAuthenticated
        ? <Component {...props} />
        : <Redirect to='/sign-in' />
    )} />
  );
}

export default PrivateRoute;
