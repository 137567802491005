import styled from "styled-components";

export const FooterContainer = styled.div`
  height: 5rem;
  width: 100%;
  background: var(--background);

  padding 0px 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding-left: 6rem;

  &:hover .nav-link {
    display: block
  }

  @media screen and (max-width: 768px) {
    padding 0px 1rem;
	}
`;

export const FooterElement = styled.div`
	color: white;
`;

export const FooterLogo = styled.img`
	height: 3rem;
	padding: 5px;
`;
