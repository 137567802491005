import { useEffect, useState } from "react";
import settings from "../../../settings.json";
import APIS from "../../../apis";
import AuthService from "../../../components/auth.service";
import axios from "axios";
import { CircularProgress } from "../../../components/CircularProgress";
import SavedProjectType from "./SavedProjectType";
import {
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { Paper, Table } from "../../../components/Table";
import SavedProject from "./SavedProject";
import { Button } from "../../../components/Button";
import { useHistory } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SavedProjectsList = (props: {
	items: SavedProjectType[];
	setItems: (newItems: SavedProjectType[]) => void;
}) => {
	const { items, setItems } = props;
	const history = useHistory();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				width: "100%",
				maxWidth: "1280px",
			}}
		>
			<div>
				<Button onClick={() => history.push("/projects")}>
					<FontAwesomeIcon icon={faPlus} />
					<span style={{ marginLeft: "1rem" }}>Aggiungi progetto</span>
				</Button>
			</div>
			{items.length === 0 ? (
				<span>Non ci sono progetti salvati</span>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="collapsible table">
						<TableHead>
							<TableRow>
								<TableCell>Nome</TableCell>
								<TableCell>Progetto</TableCell>
								<TableCell></TableCell>
								<TableCell></TableCell>
								<TableCell width={10}></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{items.map((item, index) => (
								<SavedProject
									key={index}
									item={item}
									setItem={(newItem: SavedProjectType | null) => {
										let itemsCopy = [...items];
										if (newItem) itemsCopy[index] = newItem;
										else
											itemsCopy = itemsCopy.filter(
												(item, elIndex) => elIndex != index
											);
										setItems(itemsCopy);
									}}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default SavedProjectsList;
