import {
	FooterContainer,
	FooterElement,
	FooterLogo,
} from "./components/structure/Footer";

import logo from "./resources/images/logo.png";

const Footer = () => {
	return (
		<FooterContainer>
			<FooterElement>
				<a
					style={{ textDecoration: "underline", color: "white" }}
					href="https://status.carpenteriasoldini.it/status/makeasy"
					target="_blank"
				>
					Service status
				</a>
			</FooterElement>
			<FooterElement>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "5px",
					}}
				>
					<span>Powered by</span>
					<a href="https://www.carpenteriasoldini.it">
						<FooterLogo src={logo} />
					</a>
				</div>
			</FooterElement>
		</FooterContainer>
	);
};

export default Footer;
