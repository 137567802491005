import React, { useEffect, useState } from "react";

// User
import AuthService from "./../components/auth.service";

import { useSelector, useDispatch } from "react-redux";
import {} from "./../features/user/userSlice";
import { cartOpen, cartClose } from "./../features/cart/cartSlice";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Main } from "./../components/structure/Main";
import { Button, IconButton } from "./../components/Button";

// Components
import { Title } from "./../components/Heading";

import "date-fns";

//Toats
import { toast } from "react-toastify";

// Icons
import DoneIcon from "@material-ui/icons/Done";
import { useAppDispatch } from "../hooks";
import CartDrawer from "../features/items/cart/CartDrawer";

import PanicItemsList from "../features/items/panic_items/PanicItemsList";
import PanicItemType from "../features/items/panic_items/panicItemType";
import CartItemType from "../features/items/cart/CartItemType";
import SavedProjectsList from "../features/items/saved_projects/SavedProjectsList";
import SavedProjectType from "../features/items/saved_projects/SavedProjectType";
import {
	Route,
	BrowserRouter as Router,
	Switch,
	useHistory,
} from "react-router-dom";
import axios from "axios";
import settings from "./../settings.json";
import APIS from "./../apis";
import { CircularProgress } from "../components/CircularProgress";
import PanicItemsPage from "../features/items/panic_items/PanicItemsPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClipboardList,
	faPencilRuler,
	faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";

const drawerWidth = 380;

const useStyles = makeStyles((theme) =>
	createStyles({
		appBar: {
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["margin", "width"], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginRight: drawerWidth,
		},
	})
);

export const ItemsPage = (props: { match: { path: any } }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const permissions = AuthService.getPermission();

	// panic items
	const [panicItems, setPanicItems] = useState<PanicItemType[]>([]);
	const [panicItemsstate, setPanicItemsState] = useState<
		"idle" | "loading" | "completed" | "error"
	>("idle");
	const [panicItemsError, setPanicItemsError] = useState("");

	// saved projects
	const [savedProjects, setSavedProjects] = useState<SavedProjectType[]>([]);
	const [savedProjectsState, setSavedProjectsState] = useState<
		"idle" | "loading" | "completed" | "error"
	>("idle");
	const [savedProjectsError, setSavedProjectsError] = useState("");

	useEffect(() => {
		console.log(permissions);
		// select page
		if (permissions?.includes("can_access_panic_items"))
			history.push("/items/panic");
		else if (permissions?.includes("can_access_projects"))
			history.push("/items/projects");
		else if (permissions?.includes("can_access_durox"))
			history.push("/items/durox");
		else history.push("");

		// panic items
		if (panicItems.length > 0) setPanicItemsState("completed");
		else if (
			panicItemsstate === "idle" &&
			permissions?.includes("can_access_panic_items")
		) {
			setPanicItemsState("loading");
			AuthService.authHeader().then((token) => {
				axios
					.get(settings.webservice_url + APIS.panicItems, { headers: token })
					.then((response) => {
						setPanicItems(response.data);
						setPanicItemsState("completed");
					})
					.catch((error) => {
						setPanicItemsError(error.response.data.detail);
						setPanicItemsState("error");
					});
			});
		}

		// saved projects
		if (savedProjects.length > 0) setSavedProjectsState("completed");
		else if (
			savedProjectsState === "idle" &&
			permissions?.includes("can_access_projects")
		) {
			setSavedProjectsState("loading");
			AuthService.authHeader().then((token) => {
				axios
					.get(settings.webservice_url + APIS.savedProjects, { headers: token })
					.then((response) => {
						setSavedProjects(response.data);
						setSavedProjectsState("completed");
					})
					.catch((error) => {
						setSavedProjectsError(error.response.data.detail);
						setSavedProjectsState("error");
					});
			});
		}
	}, []);

	const selectCartItems = () => {
		let cartItems: CartItemType[] = [];

		// panic items
		for (const item of panicItems) {
			if (item.cart_quantity > 0) {
				cartItems.push({
					name: item.id + " - " + item.name,
					quantity: item.cart_quantity,
					unitPrice: item.unit_price,
				});
			}
		}

		// saved projects
		for (const item of savedProjects) {
			if (item.cart_quantity > 0) {
				cartItems.push({
					name: item.name + " - " + item.project_name,
					quantity: item.cart_quantity,
					unitPrice: item.unit_price,
				});
			}
		}

		return cartItems;
	};

	const handleClearCart = () => {
		// panic items
		let panicItemsCopy = [...panicItems];
		for (let i = 0; i < panicItems.length; i++) {
			panicItemsCopy[i] = { ...panicItems[i], cart_quantity: 0 };
		}

		setPanicItems(panicItemsCopy);

		// saved projects
		let savedProjectsCopy = [...savedProjects];
		for (let i = 0; i < savedProjects.length; i++) {
			savedProjectsCopy[i] = { ...savedProjects[i], cart_quantity: 0 };
		}

		setSavedProjects(savedProjectsCopy);
	};

	// @ts-expect-error
	const open = useSelector((state) => state.cart.open);

	// @ts-expect-error
	const newOrderState = useSelector((state) => state.orders.newOrderStatus);

	const handleDrawerToggle = () => {
		console.log("onclick");
		if (open) dispatch(cartClose());
		else dispatch(cartOpen());
	};

	if (newOrderState == "succeeded") {
		toast.dark(
			<div>
				<DoneIcon /> Ordine completato!
			</div>,
			{
				hideProgressBar: true,
			}
		);
		history.push("/orders");
	}
	return (
		<Main
			style={{
				marginRight: open ? "25rem" : "auto",
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
				}}
			>
				<div className="col-3">
					<Title>Articoli</Title>
				</div>
				{/* 
				// @ts-expect-error */}
				<div className="col-9" align="right">
					<IconButton
						style={{ display: open ? "none" : "" }}
						onClick={handleDrawerToggle}
					>
						<FontAwesomeIcon icon={faShoppingCart} size="lg" />
					</IconButton>
				</div>
			</div>

			<div>
				{permissions?.includes("can_access_panic_items") ? (
					<Button
						style={{
							backgroundColor: history.location.pathname.includes("panic")
								? "var(--primary-color)"
								: "var(--secondary-color)",
						}}
						onClick={() => history.push("panic")}
					>
						<FontAwesomeIcon icon={faClipboardList} />
						<span style={{ marginLeft: "1rem" }}>Predefiniti</span>
					</Button>
				) : null}
				{permissions?.includes("can_access_projects") ? (
					<Button
						style={{
							backgroundColor: history.location.pathname.includes("projects")
								? "var(--primary-color)"
								: "var(--secondary-color)",
						}}
						onClick={() => history.push("projects")}
					>
						<FontAwesomeIcon icon={faPencilRuler} />
						<span style={{ marginLeft: "1rem" }}>Progetti</span>
					</Button>
				) : null}
				{permissions?.includes("can_access_durox") ? (
					<Button onClick={() => history.push("durox")}>Durox</Button>
				) : null}
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<div></div>
				<Switch>
					<Route path="*/panic" exact>
						{panicItemsstate === "loading" || panicItemsstate === "idle" ? (
							<CircularProgress />
						) : panicItemsstate === "error" ? (
							<span>{panicItemsError}</span>
						) : panicItemsstate === "completed" ? (
							<PanicItemsPage items={panicItems} setItems={setPanicItems} />
						) : null}
					</Route>
					<Route path="*/projects" exact>
						{savedProjectsState === "loading" ||
						savedProjectsState === "idle" ? (
							<CircularProgress />
						) : savedProjectsState === "error" ? (
							<span>{savedProjectsError}</span>
						) : savedProjectsState === "completed" ? (
							<SavedProjectsList
								items={savedProjects}
								setItems={setSavedProjects}
							/>
						) : null}
					</Route>
					<Route path="*/durox" component={PanicItemsList} exact>
						<div>Work in progress</div>
					</Route>
				</Switch>
				<div></div>
			</div>
			<CartDrawer items={selectCartItems()} clearCart={handleClearCart} />
		</Main>
	);
};

export default ItemsPage;
