import { default as OriginalTable } from "@material-ui/core/Table";
import { default as OriginalTableRow } from "@material-ui/core/TableRow";
import { default as OriginalTableCell } from "@material-ui/core/TableCell";
import { default as OriginalPaper } from "@material-ui/core/Paper";
import { default as OriginalCheckbox } from "@material-ui/core/Checkbox";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

export const Table = withStyles((theme: Theme) =>
	createStyles({
		root: {},
	})
)(OriginalTable);

export const TableRow = withStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: "transparent",
		},
		selected: {
			backgroundColor: "var(--selected) !important",
		},
		hover: {
			backgroundColor: "var(--grey-dark) !important",
		},
	})
)(OriginalTableRow);

export const TableCell = withStyles((theme: Theme) =>
	createStyles({
		root: {
			color: "white",
			fontFamily: "'Fira Sans', sans-serif",
			borderBottomColor: "var(--grey-light)",
		},
		stickyHeader: {
			backgroundColor: "var(--grey)",
		},
	})
)(OriginalTableCell);

export const Paper = withStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: "var(--grey)",
		},
	})
)(OriginalPaper);

export const Checkbox = withStyles((theme: Theme) =>
	createStyles({
		checked: {
			color: "var(--primary-color) !important",
		},
	})
)(OriginalCheckbox);
