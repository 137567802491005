import axios from "axios";
import settings from "./../settings.json";
import APIS from "../apis";

const accessTokenExpirationTime = 5;
const refreshTokenExpirationTime = 720;

function setWithExpiry(key: string, value: any, ttl: number) {
	const now = new Date();

	const item = {
		value: value,
		expiry: now.getTime() + ttl * 60 * 1000,
	};
	localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key: string) {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}

const handleLogin = (email: any, password: any) => {
	return axios
		.post(settings.webservice_url + APIS.token, {
			email,
			password,
		})
		.then((response) => {
			if (response.status === 200) {
				setWithExpiry(
					"access",
					response.data.access,
					accessTokenExpirationTime
				); //5 minutes
				setWithExpiry(
					"refresh",
					response.data.refresh,
					refreshTokenExpirationTime
				); //1 day
				localStorage.setItem("companyName", response.data.company_name);
				localStorage.setItem("customerId", response.data.customer_id);
				localStorage.setItem("permissions", response.data.permissions);
				return true;
			}
		})
		.catch((error) => {
			if (error.response) {
				if (error.response.status === 401) throw new Error("CredentialsError");
				// @ts-expect-error
				else throw new Error("GenericError", { error });
			} else throw new Error("ConnectionError");
		});
};

class AuthService {
	constructor() {
		this.authHeader = this.authHeader.bind(this);
	}

	login(email: string, password: string, to_remember = false) {
		return handleLogin(email, password);
	}

	logout() {
		localStorage.removeItem("access");
		localStorage.removeItem("refresh");
		localStorage.removeItem("companyName");
		localStorage.removeItem("customerId");
		localStorage.removeItem("permissions");
	}

	register(email: any, password: any) {
		return axios.post("api/paciock/", {
			email,
			password,
		});
	}

	authHeader() {
		const user = getWithExpiry("access");
		const refresh = getWithExpiry("refresh");

		var promise = new Promise(function (resolve, reject) {
			if (user) {
				console.log("User present");
				resolve({ Authorization: "Bearer " + user });
			} else if (!user && refresh) {
				console.log("User not present but refreshing");
				axios
					.post(settings.webservice_url + APIS.tokenRefresh, {
						refresh: refresh,
					})
					.then((response) => {
						if (response.data.access) {
							setWithExpiry(
								"access",
								response.data.access,
								accessTokenExpirationTime
							); //5 minutes
							//setWithExpiry('refresh', response.data.refresh, 1440) //1 day
							resolve({ Authorization: "Bearer " + response.data.access });
						}
					});
			} else if (!user && !refresh) {
				console.log("No credentials saved");
				reject("No credentials saved");
			}
		});
		return promise;
	}

	getCompanyName() {
		return localStorage.getItem("companyName");
	}

	getCustomerId() {
		return localStorage.getItem("customerId");
	}

	getCurrentUser() {
		return getWithExpiry("refresh");
	}

	getPermission() {
		return localStorage.getItem("permissions");
	}
}

export default new AuthService();
