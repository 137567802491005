import { createSlice, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from "../../store";

// Api
import axios from "axios";
import AuthService from "./../../components/auth.service";
import settings from './../../settings.json';

interface Project {
  readonly name: string;
  readonly description: string;
}

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async () => {
  let result: Project[] = []
  
  const getToken = AuthService.authHeader();
  
  await getToken.then(async (token) => {
    await axios.get(settings.webservice_url + "projects", { headers: token })
    .then(response => {
      result = result.concat(response.data)
    })
    .catch((error) => {
      throw error
    })
  })
  
  // Add durox project if available
  const permissions = AuthService.getPermission()
  if (permissions){    
    if (permissions.includes("can_access_durox")) {
      result.push({name: "Durox", description: "Crea articoli durox da file excel"})
    }
  }

  return result
})


interface projectsState {
  projects: Project[];
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  error: string
  activeProject: string
}

const initialState: projectsState = {
  projects: [],
  status: 'idle',
  error: '',
  activeProject: ''
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setActiveProject(state, action) {
      state.activeProject = action.payload;
    },
  },
  extraReducers: {
    // @ts-expect-error
    [fetchProjects.pending]: (state, action) => {
      state.status = 'loading'
    },
    // @ts-expect-error
    [fetchProjects.fulfilled]: (state, action) => {
        state.status = 'succeeded';
        // Add any fetched posts to the array
        state.projects = action.payload;

    },
    // @ts-expect-error
    [fetchProjects.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  }
})

export default projectsSlice.reducer

export const { setActiveProject } = projectsSlice.actions

export const selectAllProjects = (state: RootState) => state.projects.projects