import { default as OriginalDialog } from "@material-ui/core/Dialog";
import {
	makeStyles,
	useTheme,
	Theme,
	createStyles,
	withStyles,
} from "@material-ui/core/styles";

export const Dialog = withStyles((theme) =>
	createStyles({
		paper: (props) => ({
			backgroundColor: "var(--secondary-color)",
			maxWidth: props.block ? "calc(100vw * 2/3)" : "auto",
			width: props.block ? "calc(100vw * 2/3)" : "auto",
		}),
	})
)(OriginalDialog);
