import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Card, CardTitle } from "../../../components/Card";
import {
	TextInput,
	TextInputContainer,
	TextInputLabel,
} from "../../../components/Form";
import { KeyboardDatePicker } from "../../../components/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";

const CartInfo = (props: {
	description: string;
	setDescription: (newDescription: string) => void;
	deliveryDate: string;
	setDeliveryDate: (newDeliveryDate: string) => void;
}) => {
	const { description, setDescription, deliveryDate, setDeliveryDate } = props;

	return (
		<Card margin="0px" padding="10px" direction="column" align="center">
			<CardTitle>Informazioni</CardTitle>
			<TextInputContainer block>
				<TextInputLabel htmlFor="name">Indetificativo</TextInputLabel>
				<TextInput
					style={{ width: "100%" }}
					id="name"
					name="name"
					type="text"
					placeholder="(facoltativo)"
					value={description}
					onChange={(e) => {
						setDescription(e.target.value);
					}}
				/>
			</TextInputContainer>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
				<KeyboardDatePicker
					style={{ width: "100%" }}
					disableToolbar
					variant="inline"
					format="dd/MM/yyyy"
					margin="normal"
					id="date-picker-inline"
					label="Data di consegna"
					minDate={Date.now()}
					value={deliveryDate}
					onChange={(newValue: Date) => {
						try {
							const newDeliveryDate = newValue.toJSON().slice(0, 10);
							setDeliveryDate(newDeliveryDate);
						} catch {}
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
					autoOk={true}
				/>
			</MuiPickersUtilsProvider>
		</Card>
	);
};

export default CartInfo;
