import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks";
import AuthService from "../../../components/auth.service";
import { selectCartOpen } from "../../cart/cartSlice";
import axios from "axios";
import { TableCell, TableRow } from "@material-ui/core";
import { CircularProgress } from "../../../components/CircularProgress";
import { ReactSVG } from "react-svg";
import { Checkbox } from "../../../components/Table";
import OutlinedNumberInput from "../../../components/OutlinedNumberInput";
import APIS from "../../../apis";
import settings from "./../../../settings.json";
import PanicItemType from "./panicItemType";
import { SearchField } from "../../../components/Form";

const PanicItem = (props: {
	item: PanicItemType;
	setItem: (item: PanicItemType) => void;
}) => {
	const { item, setItem } = props;
	const [imageSrc, setImageSrc] = useState<string>();
	const [imageState, setImageState] = useState<
		"idle" | "loading" | "completed" | "error"
	>("idle");
	const [imageError, setImageError] = useState("");
	const cartOpen = useAppSelector(selectCartOpen);

	useEffect(() => {
		if (imageState === "idle") {
			// localstorage
			const localStorageImage = localStorage.getItem(item.id + "_image");
			if (localStorageImage) {
				setImageSrc(
					URL.createObjectURL(
						new Blob([JSON.parse(localStorageImage)], {
							type: "image/svg+xml",
						})
					)
				);
				setImageState("completed");
				return;
			}

			// fetch
			const getToken = AuthService.authHeader();
			getToken.then((token) => {
				setImageState("loading");
				axios
					.get(item.svg, { headers: token })
					.then((response) => {
						const newImageSrc = URL.createObjectURL(
							new Blob([response.data], {
								type: "image/svg+xml",
							})
						);

						// setItem fails when localStorage is full
						try {
							localStorage.setItem(
								item.id + "_image",
								JSON.stringify(response.data)
							);
						} catch (e) {
							console.log("failed to save image to local storage", e);
						}

						setImageSrc(newImageSrc);
						setImageState("completed");
					})
					.catch((error) => {
						console.log(error);
						if (error.response) {
							setImageError(error.response.data.detail);
						} else {
							setImageError("unexpected error");
						}
						setImageState("error");
					});
			});
		}
	}, []);

	let editTimer: NodeJS.Timeout | null;
	const handleNumberChange = (newValue: number) => {
		if (editTimer) clearTimeout(editTimer);
		editTimer = setTimeout(() => {
			if (newValue != item.cart_quantity) patchCartNumber(newValue);
		}, 500);
	};

	const handleToggleItemInCart = () => {
		if (item.cart_quantity > 0) patchCartNumber(0);
		else patchCartNumber(1);
	};

	const patchCartNumber = (newValue: number) => {
		const data = {
			cart_quantity: newValue,
		};
		AuthService.authHeader().then((token) => {
			axios
				.patch(
					settings.webservice_url + APIS.panicItems + item.id + "/",
					data,
					{ headers: token }
				)
				.then((response) => {
					setItem(response.data);
				})
				.catch((error) => {
					console.error(error);
				});
		});
	};

	return (
		<TableRow>
			<TableCell>{item.id}</TableCell>
			<TableCell>{item.name}</TableCell>
			<TableCell>
				{imageState === "loading" || imageState === "idle" ? (
					<CircularProgress />
				) : imageState === "error" ? (
					<span>{imageError}</span>
				) : imageState === "completed" && imageSrc ? (
					<ReactSVG
						beforeInjection={(svg) => {
							svg.setAttribute("style", "height: 220px; width: 220px");
							// @ts-expect-error
							const paths = [...svg.querySelectorAll("path")];

							for (const path of paths) {
								path.setAttribute("stroke", "white");
								path.setAttribute("fill", "transparent");
							}
						}}
						src={imageSrc}
					/>
				) : null}
			</TableCell>
			<TableCell>
				{cartOpen ? (
					<Checkbox
						checked={item.cart_quantity > 0 ? true : false}
						onChange={handleToggleItemInCart}
					/>
				) : null}
			</TableCell>
			<TableCell>
				{item.cart_quantity > 0 && cartOpen ? (
					<OutlinedNumberInput
						initValue={item.cart_quantity}
						minValue={1}
						onChange={handleNumberChange}
					/>
				) : null}
			</TableCell>
		</TableRow>
	);
};

export default PanicItem;
