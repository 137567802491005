import styled from "styled-components";

export const Chip = styled.span`
	color: white;
	text-align: ${(props) => (props.align ? props.align : "left")};
	background-color: ${(props) => (props.color ? props.color : "white")};
	padding: 5px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	width: min-content;
`;
