import React, { useEffect } from 'react'
import {
  MuiPickersUtilsProvider as OriginalMuiPickersUtilsProvider,
  KeyboardDatePicker as OriginalKeyboardDatePicker
} from '@material-ui/pickers';
import './DatePicker.css';

export const KeyboardDatePicker = (props) => {
  return(
    <OriginalKeyboardDatePicker {...props} />
  );
}
