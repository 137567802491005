import { Drawer, DrawerFooter } from "../../../components/Drawer";
import { Button, IconButton, SendButton } from "../../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title, SecondTitle } from "../../../components/Heading";

import CartList from "./CartList";
import { Content } from "../../../components/Content";
import { CircularProgress } from "../../../components/CircularProgress";

import SendIcon from "@material-ui/icons/Send";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "@material-ui/icons/Delete";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { cartClose, cartOpen } from "../../cart/cartSlice";
import React, { useState } from "react";
import CartItemType from "./CartItemType";
import AuthService from "../../../components/auth.service";
import APIS from "../../../apis";
import settings from "./../../../settings.json";
import axios from "axios";
import CartInfo from "./CartInfo";
import { useHistory } from "react-router-dom";

const CartDrawer = (props: {
	items: CartItemType[];
	clearCart: () => void;
}) => {
	const { items, clearCart } = props;
	const dispatch = useAppDispatch();
	const [description, setDescription] = useState<string>("");
	const [deliveryDate, setDeliveryDate] = useState<string>(
		new Date().toJSON().slice(0, 10)
	);
	const [newOrderState, setNewOrderState] = useState<
		"idle" | "loading" | "failed" | "succeeded"
	>("idle");
	const [newOrderError, setNewOrderError] = useState<string>("");

	const open = useAppSelector((state) => state.cart.open);

	const history = useHistory();

	const handleSubmit = () => {
		setNewOrderState("loading");
		AuthService.authHeader().then((token) => {
			const data = {
				name: description !== "" ? description : "Particolari carpenteria",
				delivery_date: deliveryDate,
			};

			axios
				.post(settings.webservice_url + APIS.makeOrder, data, {
					headers: token,
				})
				.then((response) => {
					setNewOrderState("succeeded");
					history.push("/orders");
				})
				.catch((error) => {
					console.error(error);
					setNewOrderState("failed");
					setNewOrderError(error.detail);
				});
		});
	};

	const handleCartClear = () => {
		AuthService.authHeader().then((token) => {
			axios
				.delete(settings.webservice_url + APIS.cart, { headers: token })
				.then((response) => {
					clearCart();
				})
				.catch((error) => {
					console.log(error);
				});
		});
	};

	const handleDrawerToggle = () => {
		if (open) dispatch(cartClose());
		else dispatch(cartOpen());
	};

	const totalPrice = items.reduce((prevValue, item) => {
		return prevValue + item.unitPrice * item.quantity;
	}, 0);

	return (
		<Drawer open={open}>
			<div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr" }}>
				<IconButton
					onClick={handleDrawerToggle}
					style={{ width: "3rem", height: "3rem" }}
				>
					<FontAwesomeIcon className="nav-icon" icon={faArrowLeft} />
				</IconButton>
				<Title>Ordine</Title>
			</div>

			<CartInfo
				description={description}
				setDescription={setDescription}
				deliveryDate={deliveryDate}
				setDeliveryDate={setDeliveryDate}
			/>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<SecondTitle style={{ marginTop: 20, marginLeft: 5 }}>
					Articoli
				</SecondTitle>
				{items.length > 0 ? (
					<Button onClick={handleCartClear}>
						<DeleteIcon />
					</Button>
				) : null}
			</div>

			<CartList items={items} />
			<DrawerFooter>
				<SecondTitle>
					<b>Totale:</b> {totalPrice} €
					<i style={{ fontSize: "0.75rem" }}>(IVA esclusa)</i>
				</SecondTitle>
				{newOrderState == "failed" ? (
					<Content>⛔️ {newOrderError}</Content>
				) : (
					<SendButton
						variant="extended"
						onClick={handleSubmit}
						disabled={items.length === 0}
					>
						{newOrderState == "loading" ? (
							<CircularProgress />
						) : (
							<React.Fragment>
								<SendIcon /> Ordina
							</React.Fragment>
						)}
					</SendButton>
				)}
			</DrawerFooter>
		</Drawer>
	);
};
export default CartDrawer;
