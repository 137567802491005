import styled from "styled-components";

interface NavbarContainerProps {
	hide?: boolean;
}

export const NavbarContainer = styled.div<NavbarContainerProps>`
	width: 5rem;
	height: 100vh;
	position: fixed;
	background: var(--grey-dark);
	transition: 200ms ease;
	overflow: hidden;
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;

	&:hover {
		width: 16rem;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	}
	
	//&:hover .nav-link {
	//	display: block;
	//}

	@media screen and (max-width: 768px) {
		position: fixed;
		top: ${(props) => (props.hide ? "-5rem" : "0")};
		height: 5rem;
		width: 100vw;

		&:hover {
			width: 100vw;
			box-shadow: none;
		}
	}
`;

interface NavbarLinkInterface {
	hide?: boolean;
}

export const NavbarList = styled.ul<NavbarLinkInterface>`
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	background: var(--grey-dark);
	transition: bottom 200ms;

	@media screen and (max-width: 768px) {
		position: fixed;
		bottom: ${(props) => (props.hide ? "-5rem" : "0")};
		left: 0;
		right: 0;
		flex-direction: row;
		height: 5rem;
	}
`;

export const NavbarItem = styled.li`
	display: flex;
	align-items: center;
	text-align: center;
	height: 5rem;
	text-decoration: none;
	&:last-child {
		margin-top: auto;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

export const NavbarTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	width: 100%;
	text-align: center;
	padding: 10px 0px 10px 0px;
	background-color: var(--secondary-color);

	@media screen and (max-width: 768px) {
		height: 5rem;
	}
`;

export const NavbarLink = styled.a`
  color: white;
  width: 100%;
  height: 5rem;
  transition: 200ms ease;
  
  display: grid;
  grid-template-columns: 5rem; 100%;
  justify-items: left;
  align-items: center;
  grid-auto-flow: column;
  gap: 0.5rem;

  text-decoration: none !important;

  &:hover {
    color: var(--primary-color);
    transition: 0ms;
    background-color: var(--secondary-color);
  }

  & .nav-icon{
    font-size: 2rem;
    margin: 0 1.5rem;
  }

	@media screen and (max-width: 768px) {
		justify-content: center;
	}
`;

export const NavbarAccount = styled(NavbarLink)`
  display: grid;
  grid-template-columns: 5rem; 90%;
  justify-items: center;
  align-items: center;
  grid-auto-flow: column;
  gap: 0.5rem;
  background-color: var(--secondary-color);
  white-space: nowrap;

  &:hover {
    color: #fff;
  }

	@media screen and (max-width: 768px) {
		position: fixed;
		left: 0;
		width: auto;
	}
`;

export const NavbarExit = styled(NavbarLink)`
	position: absolute;
	bottom: 0;
  display: grid;
  grid-template-columns: 5rem; 90%;
  justify-items: center;
  align-items: center;
  grid-auto-flow: column;
  gap: 0.5rem;
  white-space: nowrap;
	
	@media screen and (max-width: 768px) {
		position: absolute;
		top: 0;
		right: 0;
		width: auto;
	}
`;

export const NavbarAccountTitle = styled.div`
	width: 2em;
	height: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	font-weight: 700;
	background-color: var(--grey-dark);
	border-radius: 50%;
`;

export const NavbarText = styled.span`
	display: block;
	width: 10rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;
