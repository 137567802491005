import React from "react";
import PropTypes from "prop-types";
//
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
//
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
//
const useStyles = makeStyles((theme) => ({
	container: {},
	labelStyle: {
		"& .MuiInputLabel-root": {
			backgroundColor: (parentBackgroundColor: { color: any }) =>
				parentBackgroundColor.color || theme.palette.background.paper,
			paddingRight: theme.spacing(0.75),
			fontSize: "1.35rem",
			lineHeight: "0.74rem",
			fontWeight: 300,
			color: theme.palette.text.primary,
		},
		"&:hover .MuiInputLabel-root": {
			backgroundColor: (parentBackgroundColor: { color: any }) =>
				parentBackgroundColor.color || theme.palette.background.paper,
			fontSize: "1.35rem",
			lineHeight: "0.74rem",
			fontWeight: 300,
			color: theme.palette.text.primary,
		},
		"& .Mui-focused.MuiInputLabel-root": {
			backgroundColor: (parentBackgroundColor: { color: any }) =>
				parentBackgroundColor.color || theme.palette.background.paper,
			padding: theme.spacing(0, 1.5, 0, 1.5),
			fontSize: "1.65rem",
			lineHeight: "0.74rem",
			fontWeight: 300,
			color: theme.palette.text.primary,
		},
	},
	inputStyle: {
		"& .MuiInputBase-input": {
			color: "white",
			textAlign: "center",
		},
		"&:hover .MuiInputBase-input": {
			color: "white",
		},
		"&.Mui-focused .MuiInputBase-input": {
			color: "white",
		},
		'& input[type="number"]': {
			"-moz-appearance": "textfield",
		},
		'& input[type="number"]::-webkit-clear-button, & input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
			{
				display: "none !important",
				margin: 0,
				"-webkit-appearance": "none",
			},
	},
	inputAdornmentStyle: {
		"& .MuiIconButton-root": {
			color: "white",
		},
		"&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
			color: "white",
		},
		"&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
			color: "white",
		},
	},
	notchedOutlineStyle: {
		//(notchedOutlineStyle is border style)
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "var(--grey-light)",
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
		"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
	},
	/**
   * NOT RECOMMENDED TO USE!
   * background color of the input element.
   * to use  it uncomment and <TextField className={clsx(classes.labelStyle, classes.backgroundStyle)} />
    backgroundStyle: {
      '& .MuiInputBase-root': {
        backgroundColor: (tryProps) => tryProps.backgroundAll,
      },
      '&:hover .MuiInputBase-root': {
        backgroundColor: 'blue',
      },
      '& .Mui-focused.MuiInputBase-root': {
        backgroundColor: 'yellow',
      },
    },
  */
}));
//
const OutlinedNumberInput = (props: {
	minValue?: number;
	initValue?: number;
	maxValue?: number;
	parentBackgroundColor?: { color: any };
	label?: string;
	onChange: (value: number) => void;
}) => {
	//
	const { parentBackgroundColor } = props;
	// @ts-expect-error
	const classes = useStyles(parentBackgroundColor);
	const [value, setValue] = React.useState<number>(() => {
		if (props.minValue !== undefined && props.initValue !== undefined) {
			if (props.initValue > props.minValue) return props.initValue;
			else return props.minValue;
		} else if (props.initValue !== undefined) return props.initValue;
		else return 0;
	});
	//

	React.useEffect(() => {
		if (props.onChange) {
			props.onChange(value);
		}
	}, [value]);

	const handleInput = (val: number, setNew = false) => {
		let res = 0;
		if (setNew) {
			res = val;
		} else {
			res = value + val;
		}

		if (props.minValue) {
			if (props.hasOwnProperty("minValue") && res < props.minValue) {
				res = props.minValue;
			}
		}

		if (props.maxValue) {
			if (props.hasOwnProperty("maxValue") && res > props.maxValue) {
				res = props.maxValue;
			}
		}

		setValue(res);
	};

	return (
		<div className={classes.container}>
			<TextField
				style={{ maxWidth: "15rem" }}
				type="number"
				variant="outlined"
				size="small"
				label={props.label}
				className={classes.labelStyle}
				value={value}
				onFocus={(e) => e.target.select()}
				onChange={(e) => handleInput(parseInt(e.target.value), true)}
				InputProps={{
					className: clsx(
						classes.inputAdornmentStyle,
						classes.inputStyle,
						classes.notchedOutlineStyle
					),
					startAdornment: (
						<InputAdornment position="start">
							<IconButton
								onClick={() => handleInput(-1)}
								disabled={value === props.minValue}
							>
								<RemoveIcon fontSize="small" />
							</IconButton>
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="start">
							<IconButton
								onClick={() => handleInput(1)}
								disabled={value === props.maxValue}
							>
								<AddIcon fontSize="small" />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</div>
	);
};
OutlinedNumberInput.defaultProps = {};
OutlinedNumberInput.propTypes = {
	label: PropTypes.string,
	initValue: PropTypes.number,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
	//
	parentBackgroundColor: PropTypes.shape({
		color: PropTypes.string,
	}),
};

export default OutlinedNumberInput;
