import styled from 'styled-components'

interface TitleProps {
  readonly align?: string
}

export const Title = styled.h1<TitleProps>`
  color: ${props => props.color ? props.color : "white"};
  text-align: ${props => props.align ? props.align : "left"};
  margin: 0px;
`;

interface SecondTitle {
  readonly align?: string;
}

export const SecondTitle = styled.h6<SecondTitle>`
  color: ${props => props.color ? props.color : "white"};
  text-align: ${props => props.align ? props.align : "left"};
`;
