import styled from "styled-components";
import { Button } from "../../components/Button";
import {
	ChoiceType,
	FieldType,
	FieldReferenceInterface,
	NumberFieldType,
	ChoiceFieldType,
	OptionFieldType,
} from "./FieldType";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { NumericInput } from "../../components/Form";

const Container = styled.div`
	background-color: var(--grey);
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	justify-items: center;
	color: white;
`;

const HorizontalDivider = styled.hr`
	border-top: 3px solid #bbb;
	width: 100%;
`;

const SelectStyle: StylesConfig = {
	container: (baseStyles, state) => ({
		...baseStyles,
		width: "100%",
	}),
	control: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: "var(--grey-dark)",
		borderRadius: 5,
		border: 0,
	}),
	singleValue: (baseStyles, state) => ({
		...baseStyles,
		color: "white",
	}),
	menu: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: "var(--grey-dark)",
		borderRadius: 5,
	}),
	option: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: state.isSelected
			? "var(--primary-color)"
			: state.isFocused
			? "var(--grey)"
			: "var(--grey-dark)",
		borderRadius: 5,
	}),
};

const NumberField = forwardRef(
	(
		props: {
			field: NumberFieldType;
		},
		ref
	) => {
		const { field } = props;
		const [value, setValue] = useState(5);

		useImperativeHandle(
			ref,
			(): FieldReferenceInterface => {
				return {
					submitForm() {
						return value;
					},
					getReady() {
						return true;
					},
				};
			},
			[value]
		);

		const handleChangeValue = (newValue: number) => {
			if (field.max_value != undefined) {
				if (newValue > field.max_value) {
					newValue = field.max_value;
				}
			}

			if (field.min_value != undefined) {
				if (newValue < field.min_value) {
					newValue = field.min_value;
				}
			}

			setValue(newValue);
		};

		return (
			<Container>
				<div
					style={{
						display: "grid",
						gap: "1rem",
						alignItems: "center",
						width: "100%",
						gridTemplateColumns: "auto 12rem",
						height: "4rem",
						paddingRight: "1rem",
					}}
				>
					<span
						style={{ color: "white", fontSize: "1rem", marginLeft: "1rem" }}
					>
						{field.label}
					</span>
					<NumericInput
						value={value}
						setValue={setValue}
						unit={field.unit_of_measure}
						maxValue={field.max_value}
						minValue={field.min_value}
					/>
				</div>
			</Container>
		);
	}
);

const ListField = forwardRef(
	(props: { name: string; field: FieldType }, ref) => {
		const { name, field } = props;
		const [list, setList] = useState(0);

		const fieldsRef = useRef<FieldReferenceInterface[]>([]);

		useImperativeHandle(
			ref,
			(): FieldReferenceInterface => {
				return {
					submitForm() {
						let result = [];
						for (let i = 0; i < list; i++) {
							result.push(fieldsRef.current[i]?.submitForm());
						}
						return result;
					},
					getReady() {
						for (let i = 0; i < list; i++) {
							if (!fieldsRef.current[i]?.getReady()) return false;
						}
						return true;
					},
				};
			},
			[list]
		);

		return (
			<Container>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "2fr auto 1fr",
						width: "100%",
						alignItems: "center",
						justifyItems: "center",
					}}
				>
					<span style={{ color: "white", fontSize: "1rem" }}>{name}</span>
					<div></div>
					<Button
						style={{ fontSize: "2rem" }}
						onClick={() => setList(list + 1)}
					>
						+
					</Button>
				</div>
				{list === 0 ? (
					<span style={{ color: "white", fontSize: "1rem" }}>
						Press "+" to add {name}
					</span>
				) : (
					<Container
						style={{
							backdropFilter: "brightness(75%)",
							backgroundColor: "transparent",
							gap: 0,
							width: "100%",
						}}
					>
						{[...Array(list)].map((x, i) => (
							<div
								key={i}
								style={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<div
									style={{
										display: "grid",
										gridTemplateColumns: "1rem auto",
										marginTop: "1rem",
										width: "100%",
										gap: "1rem",
										paddingRight: "1rem",
										paddingLeft: "1rem",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											alignItems: "center",
											width: "100%",
										}}
									>
										<button
											style={{
												fontSize: "2rem",
												backgroundColor: "transparent",
												border: 0,
												color: "white",
												justifySelf: "flex-start",
											}}
											onClick={() => setList(list - 1)}
										>
											-
										</button>
										<span style={{ color: "white", fontSize: "1.5rem" }}>
											{i}
										</span>
									</div>
									<FieldTree
										field={field}
										ref={(el: FieldReferenceInterface) =>
											(fieldsRef.current[i] = el)
										}
									/>
								</div>
								<HorizontalDivider />
							</div>
						))}
					</Container>
				)}
			</Container>
		);
	}
);

const ChoiceField = forwardRef((props: { field: ChoiceFieldType }, ref) => {
	const { field } = props;
	const [selected, setSelected] = useState("");
	const selectedFieldRef = useRef<FieldReferenceInterface>(null);

	useImperativeHandle(
		ref,
		(): FieldReferenceInterface => {
			return {
				submitForm() {
					if (selected === "") return {};
					return { [selected]: selectedFieldRef.current?.submitForm() };
				},
				getReady() {
					if (!selectedFieldRef.current) return false;
					return selected != "" && selectedFieldRef.current?.getReady();
				},
			};
		},
		[selected]
	);

	if (field) {
		const choice: ChoiceType | undefined =
			selected !== ""
				? field.choices.find((choice: ChoiceType) => choice.name === selected)
				: undefined;

		const options = field.choices.map((choice: ChoiceType) => ({
			value: choice.name,
			label: choice.name,
		}));
		return (
			<Container style={{ padding: "1rem" }}>
				<span>{field.label}</span>
				<Select
					onChange={(option) => {
						//@ts-expect-error
						setSelected(option.value);
					}}
					styles={SelectStyle}
					options={options}
				/>
				{
					//@ts-expect-error
					choice ? <FieldTree field={choice} ref={selectedFieldRef} /> : null
				}
			</Container>
		);
	} else return null;
});

const OptionField = forwardRef((props: { field: OptionFieldType }, ref) => {
	const { field } = props;
	const [selected, setSelected] = useState("");

	useImperativeHandle(
		ref,
		(): FieldReferenceInterface => {
			return {
				submitForm() {
					return selected;
				},
				getReady() {
					return selected !== "";
				},
			};
		},
		[selected]
	);

	const options = field.options.map((option) => ({
		value: option,
		label: field.unit_of_measure
			? option + " " + field.unit_of_measure
			: option,
	}));

	return (
		<Container style={{ padding: "1rem" }}>
			<span>{field.label}</span>
			<Select
				onChange={(option) => {
					//@ts-expect-error
					setSelected(option.value);
				}}
				styles={SelectStyle}
				options={options}
			/>
		</Container>
	);
});

export const FieldTree = forwardRef(
	(
		props: {
			field: FieldType;
		},
		ref
	) => {
		const { field } = props;

		const fieldsRef = useRef<FieldReferenceInterface[]>([]);

		useImperativeHandle(
			ref,
			(): FieldReferenceInterface => {
				return {
					submitForm() {
						let result: { [name: string]: any } = {};
						// @ts-expect-error
						field.fields.map((field, index) => {
							result[field.name] = fieldsRef.current[index]?.submitForm();
						});
						return result;
					},
					getReady() {
						// @ts-expect-error
						for (let i = 0; i < field.fields.length; i++) {
							if (!fieldsRef.current[i]?.getReady()) return false;
						}
						return true;
					},
				};
			},
			[field]
		);

		if (field)
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
						width: "100%",
					}}
				>
					{/*
					//@ts-expect-error */}
					{field.fields.map((field: FieldType, index: number) =>
						field.type === "number" ? (
							<NumberField
								key={index}
								field={field as NumberFieldType}
								ref={(el: FieldReferenceInterface) =>
									(fieldsRef.current[index] = el)
								}
							/>
						) : field.type === "list" ? (
							<ListField
								key={index}
								name={field.name}
								//@ts-expect-error
								field={field.field}
								ref={(el: FieldReferenceInterface) =>
									(fieldsRef.current[index] = el)
								}
							/>
						) : field.type === "group" ? (
							<FieldTree
								key={index}
								field={field}
								ref={(el: FieldReferenceInterface) =>
									(fieldsRef.current[index] = el)
								}
							/>
						) : field.type === "choice" ? (
							<ChoiceField
								key={index}
								//@ts-expect-error
								field={field}
								ref={(el: FieldReferenceInterface) =>
									(fieldsRef.current[index] = el)
								}
							/>
						) : field.type === "option" ? (
							<OptionField
								key={index}
								//@ts-expect-error
								field={field}
								ref={(el: FieldReferenceInterface) =>
									(fieldsRef.current[index] = el)
								}
							/>
						) : field.type ? (
							<span>{field.type}</span>
						) : null
					)}
				</div>
			);
		else return null;
	}
);
