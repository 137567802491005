// @ts-nocheck
import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";

// Api
import axios from "axios";
import AuthService from "./../../components/auth.service";
import settings from "./../../settings.json";
import APIS from "../../apis";

export const fetchComponents = createAsyncThunk(
	"components/fetchComponents",
	async () => {
		const getToken = AuthService.authHeader();
		return getToken.then((token) => {
			return axios
				.get(settings.webservice_url + APIS.components, { headers: token })
				.then((response) => {
					return response.data;
				})
				.catch((error) => {
					console.log({ ...error });
				});
		});
	}
);

export const componentsSlice = createSlice({
	name: "components",
	initialState: {
		components: [],
		status: "idle",
		error: null,
	},
	reducers: {},
	extraReducers: {
		[fetchComponents.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchComponents.fulfilled]: (state, action) => {
			state.status = "succeeded";
			state.components = action.payload;
		},
		[fetchComponents.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export default componentsSlice.reducer;

export const selectAllComponents = (state) => state.components.components;

export const selectComponentByUrl = (componentUrl) => (state) => {
	return state.components.components.find(
		(component) => component.url === componentUrl
	);
};

export const selectComponentsUrlsByItemUrl = (itemUrl) => (state) => {
	const components = selectAllComponents(state).slice();

	return components
		.filter((component) => component.item === itemUrl)
		.map((component) => component.url);
};
