import styled from "styled-components";

export const CenterBox = styled.div`
	width: 450px;
	margin: auto;
	background: #ffffff;
	box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
	padding: 40px 55px 45px 55px;
	border-radius: 15px;

	@media screen and (max-width: 768px) {
		position: fixed;
    margin: 0;
    top: 0;
    left: 0;

    width: 100%;
		height: 100%;
		border-radius: 0;
	}
`;
